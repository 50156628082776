import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppTitleService } from './../../services/app-title/app-title.service';

@Component({
  selector: 'app-request-title',
  templateUrl: './request-title.component.html',
  styleUrl: './request-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestTitleComponent {
  constructor(private appTitleService: AppTitleService) {}

  getPortalTitle(): Observable<string> {
    return this.appTitleService.currentWebPortal.pipe(
      map((webPortal) => {
        return webPortal?.name || '';
      }),
    );
  }
}
