import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  DocSignService,
  FormAlertService,
  MapSettingsService,
  MapStateService,
  PortalId,
  PzService,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, from, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { AuthorizedPersonControlName } from '../../../services/authorized-person-form/authorized-person-form.model';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { CLNewRequestControlName } from './services/new-request-form/new-request-form.model';
import { CLNewRequestFormService } from './services/new-request-form/new-request-form.service';
import {
  CLNewRequest,
  ConstructionLogType,
} from './services/new-request/new-request.model';

@Component({
  selector: 'app-construction-log-request',
  templateUrl: './construction-log-request.component.html',
})
export class ConstructionLogRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild('canDeactivateModal')
  canDeactivateModal: NgbModalRef;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  override controlName = CLNewRequestControlName;
  formValue: CLNewRequest;
  formNotValidTranslation: string;
  requestSuccessfullySigned = true;
  constructionLogType = ConstructionLogType;
  override portalId = PortalId.ConstructionPortalConstructionLogRequest;

  constructor(
    public clNewRequestFormService: CLNewRequestFormService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    private modalService: NgbModal,
    public override formAlertService: FormAlertService,
    public override pzService: PzService,
    public override mapSettingsService: MapSettingsService,
    public override mapStateService: MapStateService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.subscribeToFormNotValidTranslation();
    super.subscribeToDocSignTranslations();
  }

  createForm(): void {
    this.formGroup = this.clNewRequestFormService.getFormGroup();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'CONSTRUCTION_PORTAL.CONSTRUCTION_LOG.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            CLNewRequest.fromAppToApi(this.getFormValue(), apiDocuments),
            '/api/interesant/wniosek/rejestracjaDziennikaBudowy/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (err) => {
          console.error(err);
          this.handleSendAndValidateFailure();
        },
      });
  }

  override handleDocSignSuccess(): void {
    this.requestSuccessfullySigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.ConstructionPortalConstructionLogRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid && this.attachmentsComponent.areDocumentsValid()
    );
  }

  getFormValue(): CLNewRequest {
    return {
      ...this.formGroup.value,
    };
  }

  getAuthorizedDetailsFormGroup(): UntypedFormGroup {
    return this.formGroup.get(
      AuthorizedPersonControlName.AuthorizedDetailsForm,
    ) as UntypedFormGroup;
  }

  shouldShowErrorAlert(
    formControl: UntypedFormControl,
    errorName: string,
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(formControl) &&
      this.hasError(formControl, errorName)
    );
  }

  isFieldReadyToShowAlert(formControl: UntypedFormControl): boolean {
    return !!(formControl && (formControl.dirty || formControl.touched));
  }

  hasError(formControl: UntypedFormControl, errorName: string): boolean {
    return !!(
      formControl &&
      formControl.errors &&
      formControl.errors[errorName]
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.requestSuccessfullySigned) {
      return true;
    }
    return this.isRequestDirty()
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty || this.attachmentsComponent.areChosenDocuments()
    );
  }

  checkDecisionFormControlsValidAndFormValidated(): boolean {
    return (
      (!this.getDecisionNumberFormControl().valid && this.submitted) ||
      (!this.getDecisionDateFormControl().valid && this.submitted) ||
      (!this.getDecisionPublishedByFormControl().valid && this.submitted)
    );
  }

  getDecisionNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      CLNewRequestControlName.DecisionNumber,
    ) as UntypedFormControl;
  }

  getDecisionDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      CLNewRequestControlName.DecisionDate,
    ) as UntypedFormControl;
  }

  getDecisionPublishedByFormControl(): UntypedFormControl {
    return this.formGroup.get(
      CLNewRequestControlName.DecisionPublishedBy,
    ) as UntypedFormControl;
  }

  checkConstructionLogTypeFormControlValidAndFormValidated(): boolean {
    return !this.getConstructionLogTypeFormControl().valid && this.submitted;
  }

  getConstructionLogTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      CLNewRequestControlName.ConstructionLogType,
    ) as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
