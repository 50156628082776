import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RequestInProgressGuard } from "../../guards/request-in-progress/request-in-progress.guard";
import { SessionGuard } from "../../guards/session/session.guard";
import { MainRoutes } from "./../../guards/guard.models";
import { MainRequestsListComponent } from "./../../shared/main-requests-list/main-requests-list.component";
import { LandAndBuildingRegisterDataUpdateUponEntitiesRequestComponent } from "./land-and-building-register-data-update-upon-entities-request.component";
import { LBRDUUERNewDataUpdateRequestComponent } from "./new-data-update-request/new-data-update-request.component";

const landAndBuildingRegisterDataUpdateUponEntitiesRequestRoutes: Routes = [
  {
    path: MainRoutes.LandAndBuildingRegisterDataUpdateUponEntitiesRequest,
    component: LandAndBuildingRegisterDataUpdateUponEntitiesRequestComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: "",
        redirectTo: "new-request",
        pathMatch: "full",
      },
      {
        path: "new-request",
        component: LBRDUUERNewDataUpdateRequestComponent,
        canDeactivate: [RequestInProgressGuard],
      },
      {
        path: "requests-list",
        component: MainRequestsListComponent,
      },
      {
        path: "**",
        redirectTo: "new-request",
        pathMatch: "full",
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(
      landAndBuildingRegisterDataUpdateUponEntitiesRequestRoutes
    ),
  ],
  providers: [SessionGuard, RequestInProgressGuard],
  exports: [RouterModule],
})
export class LandAndBuildingRegisterDataUpdateUponEntitiesRequestRoutingModule {}
