import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, from } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { DomRefService } from '../../../../../gk-dynamic-list/services/dom-ref/dom-ref.service';
import { ValidParcelIdsResult } from '../../../../../gk-kendo/gk-kendo-grid/gk-kendo-ids-file-select/gk-kendo-ids-file-select.model';
import { GkMapPrintComponent } from '../../../../../gk-map-print/gk-map-print.component';
import { MapControl } from '../../../../controls';
import {
  AttributesFormResult,
  AttributesFormState,
  FileLoaderFormResult,
  IncreaseRangeByBufferUtils,
  MapAction,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapViewActionType,
  OpenLayersGeometryType,
  SourceActionType,
  SourceType,
  ToolActionType,
  ToolType,
  Wkt,
} from '../../../../models';
import {
  AttributesFilterService,
  MapRequestsService,
} from '../../../../services';
import { MapDrawingActionService } from '../../../../services/map-drawing-action/map-drawing-action.service';
import { ConversionUtils, MapExtentUtils, ModalUtils } from '../../../../utils';
import { AttributesFormComponent } from '../attributes-form/attributes-form.component';
import { DownloadRangeModalComponent } from '../download-range-modal/download-range-modal.component';
import { FileLoaderFormComponent } from '../file-loader-form/file-loader-form.component';

@Component({
  selector: 'gk-map-tool',
  templateUrl: './map-tool.component.html',
  providers: [MapRequestsService, AttributesFilterService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapToolComponent extends MapControl implements OnInit, OnDestroy {
  private isAlive = true;
  toolTypes: ToolType[];
  sourceTypes: SourceType[];
  noFirstSourceChosenTools: ToolType[] = [
    ToolType.RectangularExtent,
    ToolType.Print,
  ];
  noDataDerivedTools: ToolType[] = [ToolType.Info];
  editableMapObjectTypes = [MapObjectApiType.ExtentOrPolygon];
  isToolMemoryManagementActive = false;
  multiObjectResponseWarnTranslation = '';
  noSingleRangeTranslation = '';
  attributesModalIsOpen = false;
  printAttributesModalIsOpen = false;
  sourceTypeEnum = SourceType;

  readonly parcelIdentifierRegExp =
    /^\d{6}_\d\.\d{4}(\.AR_\d{1,2}){0,1}\.\d{1,4}\/{0,1}\d{0,4}$/;

  readonly maxAllowedIds = 500;

  constructor(
    protected translateService: TranslateService,
    private modalService: NgbModal,
    private mapRequestsService: MapRequestsService,
    private attributesFilterService: AttributesFilterService,
    private toastrService: ToastrService,
    private dom: DomRefService,
    private mapDrawingActionService: MapDrawingActionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchMultiObjectResponseWarnNotificationText();
    this.fetchNoSingleRangeNotificationText();
    this.setToolTypes();
    this.setSourceTypes();
  }

  getCurrentSourceType(toolType: ToolType, sourceType: SourceType): SourceType {
    const currentSourceType = this.mapState.toolsState[toolType][sourceType];

    return IncreaseRangeByBufferUtils.isIncreaseRangeByBuffer(currentSourceType)
      ? currentSourceType.type
      : undefined;
  }

  getCurrentButtonText(toolType: ToolType, sourceType: SourceType): string {
    const currentSourceType = this.mapState.toolsState[toolType][sourceType];

    return IncreaseRangeByBufferUtils.isIncreaseRangeByBuffer(currentSourceType)
      ? currentSourceType.buttonText
      : undefined;
  }

  fetchMultiObjectResponseWarnNotificationText(): void {
    this.translateService
      .get('GK.MAP.MULTI_OBJECT_SEARCH_WARN')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) =>
          (this.multiObjectResponseWarnTranslation = translation),
      );
  }

  fetchNoSingleRangeNotificationText(): void {
    this.translateService
      .get('GK.MAP.SELECT_RANGE')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translation) => (this.noSingleRangeTranslation = translation),
      );
  }

  setToolTypes(): void {
    this.toolTypes = this.getToolTypes(this.mapState.toolsState);
  }

  setSourceTypes(): void {
    this.sourceTypes = this.getSourceTypes(
      this.mapState.toolsState[this.toolType],
    );
  }

  handleToolActivation(skipActivationOfFirstSourceType?: boolean): void {
    const newActivationState = !this.isCurrentToolTypeActive();
    this.dispatchToolActivationChange(newActivationState);

    if (!newActivationState) {
      this.deactivateAllSources(this.toolType);
      return;
    }

    if (
      !_.includes(this.noFirstSourceChosenTools, this.toolType) &&
      !skipActivationOfFirstSourceType
    ) {
      this.dispatchSourceActivationChange(this.sourceTypes[0], true);
      this.handleSourceOptions(this.sourceTypes[0]);
    }

    this.deactivateOtherTools();
  }

  dispatchToolActivationChange(
    isActive: boolean,
    currentToolType = this.toolType,
  ): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.IsActiveToolChange, {
        value: isActive,
        options: { toolType: currentToolType },
      }),
    );
  }

  deactivateOtherTools(): void {
    this.toolTypes.forEach((toolType) => {
      if (this.toolType !== toolType) {
        this.dispatchToolActivationChange(false, toolType);
        this.deactivateAllSources(toolType);
      }
    });
  }

  handleSourceActivation(currentSourceType: SourceType): void {
    this.handleToolActivationFromSourceInExpandedMode();
    const newActivationState =
      !this.mapState.toolsState[this.toolType][currentSourceType].isActive;
    this.dispatchSourceActivationChange(currentSourceType, newActivationState);
    this.handleSourceOptions(currentSourceType, newActivationState);
    this.deactivateOtherSources(currentSourceType);
    if (this.isSourceGeometryType(currentSourceType)) {
      this.mapDrawingActionService.emitResetSignal();
    }
  }

  handleToolActivationFromSourceInExpandedMode(): void {
    if (!this.isToolsExpandedMode() || this.isCurrentToolTypeActive()) {
      return;
    }
    this.handleToolActivation(true);
  }

  dispatchSourceActivationChange(
    sourceType: SourceType,
    isActive: boolean,
    toolType = this.toolType,
  ): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.IsActiveSourceChange, {
        value: isActive,
        options: { toolType, sourceType },
      }),
    );
  }

  deactivateOtherSources(currentSourceType: SourceType): void {
    this.sourceTypes.forEach((sourceType) => {
      if (currentSourceType !== sourceType) {
        this.dispatchSourceActivationChange(sourceType, false);
      }
    });
  }

  isSourceGeometryType(sourceType: SourceType): boolean {
    return _.includes(Object.keys(OpenLayersGeometryType), sourceType);
  }

  deactivateAllSources(toolType: ToolType): void {
    this.getSourceTypes(this.mapState.toolsState[toolType]).forEach(
      (sourceType) =>
        this.dispatchSourceActivationChange(sourceType, false, toolType),
    );
  }

  getSourceTypeButtonClass(sourceType: SourceType): string {
    const baseClass = this.mapState.toolsState[this.toolType][sourceType].icon;
    const isToolTypeActive = this.isCurrentToolTypeActive();
    const isSourceTypeActive =
      this.mapState.toolsState[this.toolType][sourceType].isActive;

    return `${baseClass} ${
      isSourceTypeActive
        ? 'btn-color-from-db-active'
        : isToolTypeActive
          ? 'btn-outline-color-from-db-active bg-white text-secondary'
          : 'btn-outline-color-from-db bg-white text-secondary'
    }`;
  }

  maybeExitFromFullscreenMode(): void {
    if (this.dom.nativeDom.fullscreenElement) {
      this.dom.nativeDom.exitFullscreen();
    }
  }

  handleSourceOptions(sourceType: SourceType, isActive = true): void {
    if (sourceType === SourceType.PrintAttributesForm && isActive) {
      this.maybeExitFromFullscreenMode();
      this.handleMapPrintByAttributesForm();

      return;
    }
    if (sourceType === SourceType.QuickPrint && isActive) {
      this.handleMapQuickPrint();

      return;
    }
    if (sourceType === SourceType.AttributesForm && isActive) {
      this.maybeExitFromFullscreenMode();
      this.handleAttributesFormOptions();
      return;
    }

    if (sourceType === SourceType.File && isActive) {
      this.maybeExitFromFullscreenMode();
      this.handleFileLoaderFormOptions();
    }
    if (sourceType === SourceType.SearchByRangeFromFile && isActive) {
      this.maybeExitFromFullscreenMode();
      this.handleSearchObjectsByRangeFromFileFormOptions();
    }

    if (sourceType === SourceType.SearchByIdFromFile && isActive) {
      this.maybeExitFromFullscreenMode();
      this.handleSearchObjectsByIdFromFileFormOptions();
    }

    if (sourceType === SourceType.DownloadRangeFile && isActive) {
      this.maybeExitFromFullscreenMode();
      this.handleDownloadRangeFileFormOptions();
    }
    if (sourceType === SourceType.Clear && isActive) {
      this.clearSpecificMapObjects();
    }
  }

  clearSpecificMapObjects(): void {
    switch (this.toolType) {
      case ToolType.Measurement:
        this.clearMeasurements();
        break;
      default:
        console.error(
          `Clearing map objects for tool type ${this.toolType} is not implemented.`,
        );
    }
  }

  clearMeasurements(): void {
    this.dispatch.emit(new MapAction(ToolActionType.ClearMeasurements));
    this.dispatch.emit(
      new MapAction(ToolActionType.ClearAllMapObjects, {
        options: { toolType: this.toolType },
      }),
    );
    this.mapDrawingActionService.emitBreakSignal();
  }

  isToolsExpandedMode(): boolean {
    return this.mapState.toolbarState.toolsExpandedMode;
  }

  isCurrentToolTypeActive(): boolean {
    return this.mapState.toolsState[this.toolType].isActive;
  }

  handleMapPrintByAttributesForm(): void {
    if (this.printAttributesModalIsOpen) {
      return;
    }
    const attributesModal = this.getModalRefWithTransferredProperties(
      GkMapPrintComponent,
      undefined,
      () => {
        this.printAttributesModalIsOpen = true;
      },
      { windowClass: 'modal-custom-size-xxl' },
    );
    const componentInstance: GkMapPrintComponent =
      attributesModal.componentInstance;
    componentInstance.dispatch = this.dispatch;
    componentInstance.setDeepCopyOfMapState(this.mapState);
    attributesModal.result.then(() => {
      this.printAttributesModalIsOpen = false;
      this.dispatchSourceActivationChange(
        SourceType.PrintAttributesForm,
        false,
      );
    });
  }

  handleMapQuickPrint(): void {
    const printContainerHTML = this.dom.nativeDom.querySelector(
      'aol-map',
    ) as HTMLElement;

    combineLatest([
      from(
        html2canvas(printContainerHTML, {
          scale: 1,
          useCORS: true,
          allowTaint: true,
        }),
      ),
      this.translateService.get('GK.MAP.PRINT.QUICK_PRINT_FILE_NAME'),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([canvas, fileName]) => {
        const contentDataURL = canvas.toDataURL('image/png', 1.0);
        const downloadLink = document.createElement('a');
        downloadLink.href = contentDataURL;
        downloadLink.download = `${fileName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  handleAttributesFormOptions(): void {
    if (this.attributesModalIsOpen) {
      return;
    }
    const attributesModal = this.getModalRefWithTransferredProperties(
      AttributesFormComponent,
      undefined,
      () => {
        this.attributesModalIsOpen = true;
      },
    );
    attributesModal.componentInstance.dispatch = this.dispatch;
    attributesModal.result.then((result) => {
      this.attributesModalIsOpen = false;
      this.dispatchAttributesFormResult(result);
      this.dispatchSourceActivationChange(SourceType.AttributesForm, false);
    });
  }

  getModalRefWithTransferredProperties(
    entryComponent: any,
    sourceType?: SourceType,
    additionalOpenCallback?: () => any,
    customModalOptions: NgbModalOptions = {},
  ): NgbModalRef {
    const modalRef = this.modalService.open(
      entryComponent,
      ModalUtils.addZIndexModalOptions({
        ...ModalUtils.getModalOptions(),
        ...customModalOptions,
      }),
    );
    if (additionalOpenCallback) {
      additionalOpenCallback();
    }
    modalRef.componentInstance.mapState = this.mapState;
    modalRef.componentInstance.toolType = this.toolType;
    if (sourceType) {
      modalRef.componentInstance.sourceType = sourceType;
    }

    return modalRef;
  }

  handleFileLoaderFormOptions(): void {
    this.getModalRefWithTransferredProperties(
      FileLoaderFormComponent,
      SourceType.File,
    ).result.then((result) => {
      this.dispatchFileLoaderFormResult(result);
      this.dispatchSourceActivationChange(SourceType.File, false);
    });
  }

  handleSearchObjectsByIdFromFileFormOptions(): void {
    this.openObjectsByIdFromFileFormInput();
  }

  openObjectsByIdFromFileFormInput(): void {
    const fileInput = this.createFileInput();
    fileInput.addEventListener('change', (event: Event) =>
      this.handleParcelFromIdInputChange(event),
    );
    fileInput.click();
  }

  createFileInput(): HTMLInputElement {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.name = 'filesInput';
    fileInput.className = 'form-control';
    return fileInput;
  }

  handleParcelFromIdInputChange(event: Event): void {
    this.dispatchSourceActivationChange(SourceType.SearchByIdFromFile, false);

    const input = event.target as HTMLInputElement;
    const file = input?.files?.[0];

    if (file) {
      this.readFileContent(file)
        .then((fileContent) => this.processParcelFileContent(fileContent))
        .catch((error) => console.error('Error reading file:', error));
    }
  }

  readFileContent(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(reader.error);
    });
  }

  processParcelFileContent(fileContent: string): void {
    const validationResult =
      this.validateParcelFileAndReturnResult(fileContent);

    if (validationResult) {
      const parcelIds =
        ConversionUtils.convertStringWithNewLinesToArray(fileContent);

      this.searchMapObjectsByParcelId(parcelIds);
    } else {
      this.showWarningMessage('GK.MAP.WRONG_FORMAT');
    }
  }

  searchMapObjectsByParcelId(parcelIds: string[]): void {
    const parcelFilter =
      this.attributesFilterService.getParcelFilter(parcelIds);

    this.mapRequestsService
      .searchMapObjects(
        this.toolType,
        parcelFilter,
        this.mapState?.viewState?.checkingIfApplicantIsEntityUrl,
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        this.dispatch.emit(
          new MapAction(ToolActionType.MapObjectsToolChange, {
            value: data.response,
            options: { toolType: this.toolType },
          }),
        );
        this.dispatch.emit(
          new MapAction(
            this.mapState.toolsState[this.toolType] &&
            this.mapState.toolsState[this.toolType].singleObjectMode
              ? MapObjectTableActionType.AddNew
              : MapObjectTableActionType.AddToExisting,
            data.response,
          ),
        );
        this.dispatch.emit(
          new MapAction(
            MapViewActionType.ExtentToFitToChange,
            MapExtentUtils.getMapExtentFromMapObjects(data.response),
          ),
        );
        this.dispatch.emit(
          new MapAction(MapObjectTableActionType.SelectRange, data.response),
        );
        this.dispatchSourceActivationChange(
          SourceType.SearchByIdFromFile,
          false,
        );
        this.dispatchTooltipLoaderVisibilityState(
          false,
          this.toolType,
          SourceType.SearchByIdFromFile,
        );
      });
  }

  validateParcelFileAndReturnResult(fileContent: string): boolean {
    const parcelIds =
      ConversionUtils.convertStringWithNewLinesToArray(fileContent);
    const validationResult = this.validateParcelIdentifiersArray(parcelIds);

    if (parcelIds.length <= 0) {
      this.showWarningMessage('GK.MAP.EMPTY_ID_FILE');
      return false;
    }

    if (parcelIds.length > this.maxAllowedIds) {
      this.showWarningMessage('GK.MAP.MAX_SIDE_ID_FILE');
      return false;
    }

    return validationResult.isValid === 'valid';
  }

  validateParcelIdentifiersArray(
    identifiersArray: Array<string>,
  ): ValidParcelIdsResult {
    const validIdentifiers: string[] = [];
    const invalidIdentifiers: string[] = [];

    identifiersArray.forEach((identifier) => {
      if (this.parcelIdentifierRegExp.test(identifier)) {
        validIdentifiers.push(identifier);
      } else {
        invalidIdentifiers.push(identifier);
      }
    });

    return ValidParcelIdsResult.fromAppToApi(
      validIdentifiers,
      invalidIdentifiers,
    );
  }

  convertStringToArray(inputString: string): Array<string> {
    return inputString.split(/\r\n|\n/).filter(Boolean);
  }

  handleSearchObjectsByRangeFromFileFormOptions(): void {
    this.getModalRefWithTransferredProperties(
      FileLoaderFormComponent,
      SourceType.SearchByRangeFromFile,
    ).result.then((result: FileLoaderFormResult) => {
      if (result && result.wkt) {
        this.searchMapObjects(result.wkt);
      } else {
        this.dispatchSourceActivationChange(
          SourceType.SearchByRangeFromFile,
          false,
        );
      }
    });
  }

  handleDownloadRangeFileFormOptions(): void {
    const selectedRanges = this.getSelectedRangeMapObjects();
    if (!selectedRanges || selectedRanges.length !== 1) {
      this.toastrService.warning(this.noSingleRangeTranslation);
      this.dispatchSourceActivationChange(SourceType.DownloadRangeFile, false);

      return;
    }
    this.getModalRefWithTransferredProperties(
      DownloadRangeModalComponent,
      SourceType.DownloadRangeFile,
    ).result.then(() => {
      this.dispatchSourceActivationChange(SourceType.DownloadRangeFile, false);
    });
  }

  searchMapObjects(wkt: Wkt): void {
    this.dispatchTooltipLoaderVisibilityState(
      true,
      this.toolType,
      SourceType.SearchByRangeFromFile,
    );

    this.mapRequestsService
      .searchMapObjects(
        this.toolType,
        this.attributesFilterService.getGeometryFilter(wkt),
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        if (
          this.mapState.toolsState[this.toolType] &&
          this.mapState.toolsState[this.toolType].singleObjectMode &&
          data.response.length > 1
        ) {
          this.toastrService.warning(this.multiObjectResponseWarnTranslation);
          this.dispatchSourceActivationChange(
            SourceType.SearchByRangeFromFile,
            false,
          );
          this.dispatchTooltipLoaderVisibilityState(
            false,
            this.toolType,
            SourceType.SearchByRangeFromFile,
          );

          return;
        }
        if (this.mapState.clearAllObjectsOnNewObjectSearch) {
          this.dispatch.emit(new MapAction(ToolActionType.ClearAllMapObjects));
          this.dispatch.emit(
            new MapAction(MapObjectTableActionType.DeselectAll),
          );
        }
        this.dispatch.emit(
          new MapAction(ToolActionType.MapObjectsToolChange, {
            value: data.response,
            options: { toolType: this.toolType },
          }),
        );
        this.dispatch.emit(
          new MapAction(
            this.mapState.toolsState[this.toolType] &&
            this.mapState.toolsState[this.toolType].singleObjectMode
              ? MapObjectTableActionType.AddNew
              : MapObjectTableActionType.AddToExisting,
            data.response,
          ),
        );
        this.dispatch.emit(
          new MapAction(
            MapViewActionType.ExtentToFitToChange,
            MapExtentUtils.getMapExtentFromMapObjects(data.response),
          ),
        );
        this.dispatch.emit(
          new MapAction(MapObjectTableActionType.SelectRange, data.response),
        );

        this.dispatchSourceActivationChange(
          SourceType.SearchByRangeFromFile,
          false,
        );
        this.dispatchTooltipLoaderVisibilityState(
          false,
          this.toolType,
          SourceType.SearchByRangeFromFile,
        );
      });
  }

  dispatchTooltipLoaderVisibilityState(
    shouldHide: boolean,
    toolType: ToolType,
    sourceType: SourceType,
  ): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.IsVisibleTooltipLoaderChange, {
        value: shouldHide,
        options: { toolType, sourceType },
      }),
    );
  }

  dispatchAttributesFormResult(result: AttributesFormResult): void {
    const sourceState = this.mapState.toolsState[this.toolType][
      SourceType.AttributesForm
    ] as AttributesFormState;

    const payloadOptions = {
      toolType: this.toolType,
      sourceType: SourceType.AttributesForm,
    };

    this.dispatchFormValueChange(result.formValue, payloadOptions);
    this.dispatchMapObjectTableStateChange(
      result.mapObjectTableState,
      payloadOptions,
    );

    if (result.zoomToSelected) {
      this.dispatchExtentToFitTo(result.mapObjectTableState.selectedMapObjects);
      this.dispatchSelect(result.mapObjectTableState.selectedMapObjects);
      if (sourceState.isEnabledRectangularExtentFit) {
        this.dispatchSourceActivationChange(SourceType.MapSheetForm, true);
        this.dispatchMapExtentChange(
          result.mapObjectTableState.selectedMapObjects,
        );

        return;
      }
    }

    if (result.mapObjects && result.mapObjects.length) {
      this.dispatchMapObjects(result.mapObjects, payloadOptions.toolType);
    }
  }

  dispatchFormValueChange(value: any, options: any): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.FormValueChange, {
        value,
        options,
      }),
    );
  }

  dispatchMapObjectTableStateChange(
    value: MapObjectTableState,
    options: any,
  ): void {
    this.dispatch.emit(
      new MapAction(SourceActionType.MapObjectTableStateChange, {
        value,
        options,
      }),
    );
  }

  dispatchMapExtentChange(selectedMapObjects: MapObject[]): void {
    this.dispatch.emit(
      new MapAction(
        MapViewActionType.MapExtentChange,
        MapExtentUtils.getMapExtentFromMapObjects(selectedMapObjects),
      ),
    );
  }

  dispatchFileLoaderFormResult(result: FileLoaderFormResult): void {
    const payloadOptions = {
      toolType: this.toolType,
      sourceType: SourceType.File,
    };

    if (result.zoomToSelected) {
      this.dispatchExtentToFitTo(result.mapObjects);
    }

    this.dispatchMapObjects(result.mapObjects, payloadOptions.toolType);
  }

  dispatchMapObjects(mapObjects: MapObject[], toolType: ToolType): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: mapObjects,
        options: { toolType },
      }),
    );

    if (!this.mapState.toolsState[toolType].isAuxiliary) {
      this.dispatch.emit(
        new MapAction(
          this.mapState.toolsState[toolType] &&
          this.mapState.toolsState[toolType].singleObjectMode
            ? MapObjectTableActionType.AddNew
            : MapObjectTableActionType.AddToExisting,
          mapObjects,
        ),
      );
    }
  }

  loadToTable(): void {
    this.dispatch.emit(
      new MapAction(
        MapObjectTableActionType.AddToExisting,
        this.getMapObjectsWithUpdatedUuidsIfEditable(),
      ),
    );
  }

  getMapObjectsWithUpdatedUuidsIfEditable(): MapObject[] {
    return this.mapState.toolsState[this.toolType].mapObjects.map(
      (mapObject) =>
        _.includes(this.editableMapObjectTypes, mapObject.type)
          ? { ...mapObject, uuid: UUID.UUID() }
          : mapObject,
    );
  }

  shouldShowToolMemoryManagementButtonsForDataDerivedTools(): boolean {
    return !_.includes(this.noDataDerivedTools, this.toolType);
  }

  eraseToolMemory(): void {
    this.dispatch.emit(
      new MapAction(ToolActionType.MapObjectsToolChange, {
        value: [],
        options: { toolType: this.toolType, newSet: true },
      }),
    );
  }

  getSelectedRangeMapObjects(): MapObject[] {
    return this.mapState.mapObjectTablesState.length === 1
      ? this.mapState.mapObjectTablesState[0].selectedMapObjects.filter(
          (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
        )
      : this.mapState.mapObjectTablesState.find(
          (mapObjectTableState) =>
            _.isEmpty(mapObjectTableState.mapObjectTypes) ||
            mapObjectTableState.mapObjectTypes.includes(
              MapObjectApiType.ExtentOrPolygon,
            ),
        ).selectedMapObjects;
  }

  showWarningMessage(translationKey: string): void {
    this.toastrService.warning(this.translateService.instant(translationKey));
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
