import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { ECSControlName } from '../../../../components/electronic-correspondence-statement/models/form/form.model';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import { SPPNewRequestControlName } from './new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class SPPNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [SPPNewRequestControlName.ProxyDetailsCheckbox]: false,
          [ECSControlName.ElectronicCorrespondenceStatement]: false,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [SPPNewRequestControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [SPPNewRequestControlName.Street]: [getDefaultControlValue()],
      [SPPNewRequestControlName.Comments]: [getDefaultControlValue()],
    });
  }
}
