<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.PLACE' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Place"
              [id]="controlName.Place"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.Place"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focusout)="setPlaceByName()"
            />

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Street">
              {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.STREET' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Street"
              [id]="controlName.Street"
              class="form-control form-control-sm"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [formControlName]="controlName.Street"
              (focusout)="setStreetByName()"
              [autocomplete]="undefined"
            />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !areLandParcelsChosen() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.MAP_LOCATION' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>

        @if (submitted && !areLandParcelsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'SUPPLEMENTARY_PARCEL_PURCHASE.LAND_PARCEL_REQUIRED' | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      <div class="card mb-4 border-dark">
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Comments">
            {{
              'SUPPLEMENTARY_PARCEL_PURCHASE.ADDITIONAL_INFORMATION' | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.Comments"
                [id]="controlName.Comments"
                class="form-control form-control-sm"
                [formControlName]="controlName.Comments"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'SUPPLEMENTARY_PARCEL_PURCHASE.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'SUPPLEMENTARY_PARCEL_PURCHASE.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>
