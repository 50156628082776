<button
  type="button"
  (click)="previewRequest()"
  [disabled]="downloadingPreviewFile"
  class="btn"
  [ngClass]="buttonClass"
>
  @if (downloadingPreviewFile) {
    <i class="fa fa-spinner fa-pulse fa-fw"></i>
    <span class="sr-only">{{ 'LOADING' | translate }}</span>
  } @else {
    {{ 'REQUEST_PREVIEW' | translate }}
  }
</button>
