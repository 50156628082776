import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ApiNewDokumentPowiazanyDalDto,
  getFileNameFromResponseHeaders,
  PortalId,
  StampDutyService,
} from '@gk/gk-modules';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, takeWhile, tap } from 'rxjs';
import { DesignerIncomingDocumentsService } from '../../services/designer-incoming-documents/designer-incoming-documents.service';
import { BaseStyleClassesDirective } from '../../shared/base-style-classes/base-style-classes.directive';
import {
  ChosenDocumentSections,
  DocumentSection,
  DocumentSectionTypeId,
} from './services/document-section/document-section.model';
import { DocumentSectionService } from './services/document-section/document-section.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
})
export class AttachmentsComponent
  extends BaseStyleClassesDirective
  implements OnDestroy, OnInit, OnChanges
{
  private isAlive = true;
  documentSections: DocumentSection[];
  chosenFiles: ChosenDocumentSections = {};
  documentsPaidInCurrentSectionIds = new Set<string | number>();
  @Input() submitted: boolean;
  @Input() portalId: PortalId;
  @Input() requiredPowerOfAttorney: boolean = undefined;
  @Input() requiredOtherFile: boolean = undefined;

  constructor(
    public documentSectionService: DocumentSectionService,
    private fileSaverService: FileSaverService,
    private incomingDocumentsService: DesignerIncomingDocumentsService,
    public stampDutyService: StampDutyService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchDocumentSections();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['requiredPowerOfAttorney']) {
      this.updateIsObligatoryProperty(
        DocumentSectionTypeId.PowerOfAttorney,
        this.requiredPowerOfAttorney,
      );
    }
    if (changes['requiredOtherFile']) {
      this.updateIsObligatoryProperty(
        DocumentSectionTypeId.OtherFile,
        this.requiredOtherFile,
      );
    }
  }

  updateIsObligatoryProperty(
    typeId: DocumentSectionTypeId,
    expectedSate: boolean,
  ): void {
    this.documentSections = this.documentSections?.map((documentSection) => {
      if (
        documentSection.id === typeId &&
        documentSection.isObligatory !== expectedSate
      ) {
        documentSection.isObligatory = expectedSate;
      }

      return documentSection;
    });
  }

  fetchDocumentSections(): void {
    this.documentSectionService
      .get(this.portalId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.documentSections = data));
  }

  shouldShowFileInputInvalidMessage(documentSection: DocumentSection): boolean {
    return (
      this.submitted &&
      !this.areChosenObligatoryDocuments(documentSection) &&
      !this.isObligatoryStampDutyPaid(documentSection)
    );
  }

  handleFileInputAction(id: string | number, files: File[]): void {
    this.chosenFiles[id] = files;
    this.notifyProxyAttachmentSelection();
  }

  notifyProxyAttachmentSelection(): void {
    const isProxyAttachmentChosen =
      !!this.chosenFiles[DocumentSectionTypeId.PowerOfAttorney]?.length ||
      !!this.chosenFiles[DocumentSectionTypeId.PowerOfAttorneyStampDuty]
        ?.length;
    const selected$ = this.documentSectionService.getProxyAttachmentsSelected(
      this.portalId,
    );
    selected$.next(isProxyAttachmentChosen);
  }

  downloadSampleDocument(sampleDocumentId: string | number): void {
    this.documentSectionService
      .downloadSampleDocument(sampleDocumentId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        const fileName = getFileNameFromResponseHeaders(data.headers);
        this.fileSaverService.save(data.body, fileName);
      });
  }

  areChosenDocuments(): boolean {
    return this.documentSections.some(
      (documentSection: DocumentSection) =>
        !!(
          this.chosenFiles[documentSection.id] &&
          this.chosenFiles[documentSection.id].length
        ),
    );
  }

  isChosenDocument(documentSectionId: string | number): boolean {
    return !!(
      this.chosenFiles[documentSectionId] &&
      this.chosenFiles[documentSectionId].length
    );
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return Object.entries(this.chosenFiles).flatMap(([id, files]) =>
      files.map((file) =>
        this.incomingDocumentsService.docFileToDtoDocument(file, id),
      ),
    );
  }

  areDocumentsValid(): boolean {
    return this.documentSections.every(
      (documentSection) =>
        this.areChosenObligatoryDocuments(documentSection) ||
        this.isObligatoryStampDutyPaid(documentSection),
    );
  }

  areChosenObligatoryDocuments(documentSection: DocumentSection): boolean {
    const files = documentSection.alternativeDocumentTypes?.length
      ? documentSection.alternativeDocumentTypes
          .map(
            (alternativeDocumentType) =>
              !!this.chosenFiles[alternativeDocumentType.id]?.length,
          )
          .filter(Boolean)
      : this.chosenFiles[documentSection.id];

    return !documentSection.isObligatory || !!(files && files.length);
  }

  isObligatoryStampDutyPaid(documentSection: DocumentSection): boolean {
    return (
      !documentSection.isObligatory ||
      documentSection.isStampDutyInitialPaid ||
      this.documentsPaidInCurrentSectionIds.has(documentSection.id)
    );
  }

  isStampDutyPaidInCurrentSession(
    documentSectionId: string | number,
  ): Observable<boolean> {
    return this.stampDutyService
      .isPaidInCurrentSession(this.portalId, documentSectionId)
      .pipe(
        tap((isPaid) => {
          if (isPaid) {
            this.documentsPaidInCurrentSectionIds.add(documentSectionId);
          }
        }),
      );
  }

  triggerStampDutyPaymentStatusCheck(documentSecionId: string | number): void {
    this.stampDutyService.deletePaymentStatusCache(
      this.portalId,
      documentSecionId,
    );
  }

  ngOnDestroy(): void {
    this.deleteDocumentSectionCache();
    this.deletePaymentStatusCacheForAllDocumentSections();
    this.isAlive = false;
  }

  deleteDocumentSectionCache(): void {
    this.documentSectionService.clearCache(this.portalId);
  }

  deletePaymentStatusCacheForAllDocumentSections(): void {
    this.documentSections?.forEach((documentSection) =>
      this.triggerStampDutyPaymentStatusCheck(documentSection.id),
    );
  }
}
