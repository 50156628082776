import { FieldId, LawPerson } from '@gk/gk-modules';
import { WorkspaceState } from '../../../../services/request-workspace-state/request-workspace-state.model';
import {
  ApplicantRequest,
  ApplicantRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface GACSDRNewRequestDto extends ApplicantRequestDto {
  SygnaturaPracy: string;
  CelPracyId: FieldId[];
  Materialy: string;
  ZakresDanych: string;
  DodatkoweInfo: string;
  ZakresPracy: string[];
}

export class GACSDRNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    public workSignature: string,
    public workPurpose: FieldId[],
    public materials: string,
    public dataRange: string,
    public additionalInfo: string,
    shippingForm: Shipping,
  ) {
    super(
      lawPerson,
      contactData,
      undefined,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: GACSDRNewRequest,
    workRanges: string[],
  ): GACSDRNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
      }),
      SygnaturaPracy: data.workSignature,
      CelPracyId: data.workPurpose,
      Materialy: data.materials,
      ZakresDanych: data.dataRange,
      DodatkoweInfo: data.additionalInfo,
      ZakresPracy: workRanges,
    };
  }
}

export interface GACSDRNewRequestFormWorkspaceState extends WorkspaceState {
  formValue: GACSDRNewRequest;
  workRanges: string[];
}
