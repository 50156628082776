import {
  EgibObject,
  EnablementType,
  LawPersonSearch,
  MapObject,
  MapState,
} from '@gk/gk-modules';
import { FSPNewRequestFormValue } from '../../modules/free-services-portal/services/new-request-form/new-request-form.model';
import { AuthorizedPersonToRegistryView } from '../authorized-person-to-registry-view/authorized-person-to-registry-view.model';
import { ConnectionAndNetworkFormGroupContent } from '../connection-and-network/connection-and-network.model';
import { NewDesignerRequestFormModel } from '../new-designer-request/new-designer-request.model';
export enum WorkspaceStateId {
  DesignerNewRequestForm = 'projektant',
  PrzpNewRequestForm = 'przp',
  GrantingRightsNewRequestForm = 'wn_nadanie_upr',
  PresentationCommentsLandBuildingRegistryProjectNewCommentsForm = 'wn_uwagi_do_wylozenia',
  GeodeticAndCartographicServiceDataRequestNewRequestForm = 'wn_dane_dla_sluzby_geo',
  LandAndBuildingRegisterDataUpdateUponEntitiesRequestForm = 'wn_aktualizacja_egib_przez_podmiot',
}

export enum BsMessageType {
  Info = 'info',
  Success = 'success',
  Danger = 'danger',
}

export enum WorkspaceStateDraftVersion {
  DesignerNewRequestFormVersion = '2',
  PrzpNewRequestFormVersion = '3',
  GrantingRightsNewRequestFormVersion = '1',
  PresentationCommentsLandBuildingRegistryProjectNewCommentsFormVersion = '1',
  GeodeticAndCartographicServiceDataRequestNewRequestFormVersion = '1',
  LandAndBuildingRegisterDataUpdateUponEntitiesRequestFormVersion = '1',
}

export type StringifiedDocFile = string | undefined;

export interface WorkspaceState {
  draftVersion?: WorkspaceStateDraftVersion;
}

export interface DesignerNewRequestFormWorkspaceState extends WorkspaceState {
  formValue: NewDesignerRequestFormModel;
  chosenParcels: EgibObject[];
  issueRanges: MapObject[];
  chosenConnectionsAndNetworksGroups: ConnectionAndNetworkFormGroupContent[];
  lawPersons: LawPersonSearch[];
}

export interface PrzpNewRequestFormWorkspaceState extends WorkspaceState {
  formValue: FSPNewRequestFormValue;
  enablementType: EnablementType;
  principal: LawPersonSearch;
  authorizedPersonsToRegistryView: AuthorizedPersonToRegistryView[];
  mapState: MapState;
  selectedLegalPersonApplicant: LawPersonSearch;
}

export const oneMegaByteInBytes = 1048576;
export const maxWorkspaceStateSizeInBytes = oneMegaByteInBytes * 30;
