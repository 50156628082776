<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.CLIENT_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLaneOccupancyPurposeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.LaneOccupancyPurpose">
            {{
              'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.OCCUPANCY_PURPOSE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.LaneOccupancyPurpose"
            [id]="controlName.LaneOccupancyPurpose"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.LaneOccupancyPurpose"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getLaneOccupancyPurposeFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLocationFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.RoadNumber">
              {{
                'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.ROAD_NUMBER' | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.RoadNumber"
              [id]="controlName.RoadNumber"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.RoadNumber"
            />

            @if (shouldShowRequiredAlert(getRoadNumberFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Place">
              {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.PLACE' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Place"
              [id]="controlName.Place"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.Place"
              [ngbTypeahead]="searchPlace"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (focusout)="setPlaceByName()"
            />

            @if (shouldShowRequiredAlert(getPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="mb-2">
            <label class="text-nowrap" [attr.for]="controlName.Street">
              {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.STREET' | translate }}
            </label>

            <input
              type="text"
              [name]="controlName.Street"
              [id]="controlName.Street"
              class="form-control form-control-sm"
              [ngbTypeahead]="searchStreet"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              [formControlName]="controlName.Street"
              (focusout)="setStreetByName()"
              [autocomplete]="undefined"
            />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isAreaWithoutRoadEngineeringFacilitiesFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.AREA_WITHOUT_ROAD_ENGINEERING_FACILITIES'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="
              handleAreaWithoutRoadEngineeringFacilitiesMapAction($event)
            "
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="
                  handleAreaWithoutRoadEngineeringFacilitiesMapAction($event)
                "
              ></gk-map-object-table>
            }
          </div>

          <div class="mb-2">
            <label
              class="text-nowrap"
              [attr.for]="controlName.AreaWithoutRoadEngineeringFacilities"
            >
              {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.AREA' | translate }}
            </label>

            <div class="input-group input-group-sm">
              <input
                type="number"
                [name]="controlName.AreaWithoutRoadEngineeringFacilities"
                [id]="controlName.AreaWithoutRoadEngineeringFacilities"
                class="form-control required"
                aria-required="true"
                [formControlName]="
                  controlName.AreaWithoutRoadEngineeringFacilities
                "
              />

              <span class="input-group-text">m²</span>
            </div>

            @if (
              shouldShowRequiredAlert(
                getAreaWithoutRoadEngineeringFacilitiesFormControl()
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>

        @if (
          submitted &&
          !areAreaWithoutRoadEngineeringFacilitiesIssueRangesChosen()
        ) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.ISSUE_RANGE_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          isDateRangeInvalid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.LaneOccupancyDate">
            {{
              'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.LANE_OCCUPANCY_DATE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <app-date-range-picker
            (dateRangeChanged)="updateDateRangeFormControl($event)"
            [required]="true"
          ></app-date-range-picker>
        </div>

        @if (isDateRangeInvalid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'PERIOD_INVALID' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.COMMENTS_LABEL' | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.COMMENTS' | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.PERSONAL_DATA_PROCESING_LABEL'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.PERSONAL_DATA_PROCESING'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'LANE_OCCUPANCY_PORTAL.BUILDING_OBJECTS.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
