import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { State } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs';
import {
  iwIconForward,
  iwIconUndo2,
} from '../../../../assets/css/ewid-svg/svg-icons';
import {
  GkKendoWindowService,
  GridToolBarItem,
  GridToolBarItemType,
  KendoWindowInstanceNames,
} from '../../../../gk-kendo';
import { LandDetailsGridDataService } from './map-land-details-grid/map-land-details-grid-data.service';
import { MapLandDetailsWrapperService } from './map-land-details-wrapper.service';

@Component({
  selector: 'gk-map-land-details-wrapper',
  templateUrl: './map-land-details-wrapper.component.html',
})
export class MapLandDetailsWrapperComponent implements OnInit, OnDestroy {
  gridToolbarOverflow = false;
  isAlive = true;
  landSelectionText = '';

  private disableNavigation = true;

  constructor(
    public landDetailsGridService: LandDetailsGridDataService,
    private mapLandDetailsWrapperService: MapLandDetailsWrapperService,
    private translateService: TranslateService,
    private gkKendoWindowService: GkKendoWindowService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.subscribeToLandSelectionChanges();
    this.subscribeToLandCountChanges();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  public subscribeToLandSelectionChanges(): void {
    this.landDetailsGridService.$selection
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((selection) => {
        const selectedItemUuid = selection?.selectedItems[0]?.Uuid;
        if (selectedItemUuid) {
          this.mapLandDetailsWrapperService.handleLandSelected(
            selectedItemUuid,
          );
        }
      });
  }

  public subscribeToLandCountChanges(): void {
    this.landDetailsGridService.$count
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((count) => {
        this.disableNavigation = count <= 1;
        this.updateLandSelectionText();
      });
  }

  private updateLandSelectionText(): void {
    const selectedNumberFormatted = (
      this.getCurrentSelectionIndex() + 1
    ).toString();

    this.translateService
      .get('LAND_DETAILS.LAND_SELECTION_TEXT', {
        selectedNumber: selectedNumberFormatted,
        totalCount: this.landDetailsGridService.$count.getValue(),
      })
      .subscribe((translatedText: string) => {
        this.landSelectionText = translatedText;
      });
  }

  private getCurrentSelectionIndex(): number {
    const selectedKey =
      this.landDetailsGridService.$selection.getValue()?.selectedKeys[0];

    return selectedKey ? Number(selectedKey) : 0;
  }

  public loadLandsFromApi(pointString: string): void {
    const payload = this.createApiPayload(pointString);
    this.landDetailsGridService
      .queryByState(payload, false)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        if (data.total === 0) {
          this.handleEmptyRequest();
        }
      });
  }

  private handleEmptyRequest(): void {
    this.gkKendoWindowService
      .getWindowInstanceByName(KendoWindowInstanceNames.LandDetailsWindow)
      .close();
    this.toastrService.error(
      this.translateService.instant('LAND_DETAILS.NO_ITEM_INFO'),
    );
  }

  private createApiPayload(pointString: string): State {
    return {
      filter: {
        filters: [
          { field: 'Geom', operator: 'intersects', value: pointString },
        ],
        logic: 'and',
      },
      take: 50,
    };
  }

  private selectPreviousLand(): void {
    const previousIndex = this.getCurrentSelectionIndex() - 1;
    if (previousIndex >= 0) {
      this.landDetailsGridService.$selectedKeys.next([previousIndex]);
    }
  }

  private selectNextLand(): void {
    const nextIndex = this.getCurrentSelectionIndex() + 1;
    if (nextIndex < this.landDetailsGridService.$count.getValue()) {
      this.landDetailsGridService.$selectedKeys.next([nextIndex]);
    }
  }

  getToolbarItems(): GridToolBarItem[] {
    return [
      {
        name: 'previous',
        svgIcon: iwIconUndo2,
        className: 'svg-icon-blue',
        callback: () => this.selectPreviousLand(),
        disabled: this.disableNavigation,
      },
      {
        name: 'currentChoosedLand',
        text: this.landSelectionText,
        type: GridToolBarItemType.Text,
      },
      {
        name: 'next',
        svgIcon: iwIconForward,
        className: 'svg-icon-blue',
        callback: () => this.selectNextLand(),
        disabled: this.disableNavigation,
      },
    ];
  }
}
