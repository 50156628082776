import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  LawPersonAddressControlName,
  LawPersonControlName,
  PostOfficeModalComponent,
  ReceivingTypesItemForms,
  ShippingForm,
  ShippingService,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, takeWhile, tap } from 'rxjs';
import { DocumentationFormType } from '../base-new-request/base-new-request.model';
import { ShippingFormControlName } from './shipping-form.model';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss'],
})
export class ShippingFormComponent implements OnInit, OnDestroy {
  private isAlive = true;
  @Input() shippingFormGroup: UntypedFormGroup;
  @Input() portalId: number | string;
  @Input() removeDisabledControls: boolean;
  @Input() idPrefix = '';
  @Input() primitivePostalCode: boolean;
  @Input() wasFormValidated: boolean;
  @Input() set documentationForm(value: DocumentationFormType) {
    this.documentationFormValue = value;
    this.setDefaultShippingForm();
  }
  get documentationForm(): DocumentationFormType {
    return this.documentationFormValue;
  }
  private documentationFormValue: DocumentationFormType;
  shippingFormControlName = ShippingFormControlName;
  lawPersonAddressControlName = LawPersonAddressControlName;
  documentationFormType = DocumentationFormType;
  shippingTypes: ShippingForm[];
  customPostOfficeModalHeaderInfo: string;
  postOfficeModalHeaderInfoTranslation: string;
  @ViewChild(PostOfficeModalComponent)
  postOfficeModalComponent: PostOfficeModalComponent;

  constructor(
    private shippingService: ShippingService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setDefaultShippingForm();
    this.setProperShippingDataInputs();
    this.fetchPostOfficeModalHeaderInfoTranslation();
  }

  setDefaultShippingForm(): void {
    this.fetchShippingForms()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        if (!this.shippingTypes?.length) {
          return;
        }
        this.getShippingFormTypeRadioFormControl().setValue(
          this.shippingTypes[0],
        );
      });
  }

  setProperShippingDataInputs(): void {
    this.disableShippingDataFormGroups();
    this.getShippingFormTypeRadioFormControl()
      .valueChanges.pipe(takeWhile(() => this.isAlive))
      .subscribe((shippingFormType: ShippingForm) => {
        this.disableShippingDataFormGroups();
        if (shippingFormType && shippingFormType.postalDataRequired) {
          this.getNameSurnameFormControl().enable();
          this.getAddressFormGroup().enable();

          return;
        }
        if (shippingFormType && shippingFormType.emailRequired) {
          this.getEmailFormControl().enable();

          return;
        }
        if (shippingFormType && shippingFormType.ftpAddressRequired) {
          this.getFtpAddressFormControl().enable();

          return;
        }
      });
  }

  fetchShippingForms(): Observable<ShippingForm[]> {
    if (!this.portalId) {
      return of([]);
    }

    return this.shippingService
      .getShippingForms(
        this.portalId,
        this.documentationForm === DocumentationFormType.ElectronicForm
          ? ReceivingTypesItemForms.Electronic
          : ReceivingTypesItemForms.Printed,
      )
      .pipe(tap((data) => (this.shippingTypes = data)));
  }

  fetchPostOfficeModalHeaderInfoTranslation(): void {
    this.translateService
      .get('SHIPPING_CUSTOM_POST_OFFICE_MODAL_HEADER_INFO')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (postOfficeModalHeaderInfoTranslation) =>
          (this.postOfficeModalHeaderInfoTranslation =
            postOfficeModalHeaderInfoTranslation),
      );
  }

  disableShippingDataFormGroups(): void {
    this.getNameSurnameFormControl().disable();
    this.getEmailFormControl().disable();
    this.getFtpAddressFormControl().disable();
    this.getAddressFormGroup().disable({ emitEvent: false });
  }

  getNameSurnameFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.NameSurname,
    ) as UntypedFormControl;
  }

  getEmailFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.Email,
    ) as UntypedFormControl;
  }

  getFtpAddressFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.FtpAddress,
    ) as UntypedFormControl;
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.shippingFormGroup.get(
      LawPersonControlName.Address,
    ) as UntypedFormGroup;
  }

  getShippingFormTypeRadioFormControl(): UntypedFormControl {
    return this.shippingFormGroup.get(
      this.shippingFormControlName.ShippingForm,
    ) as UntypedFormControl;
  }

  askForPostOfficeWhenPostalCodeIsNotFromDictionary(
    customPostOfficeModalHeaderInfo = this.postOfficeModalHeaderInfoTranslation,
  ): Observable<boolean> {
    return this.postOfficeModalComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(
      customPostOfficeModalHeaderInfo,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
