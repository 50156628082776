import { Component, OnInit } from '@angular/core';
import { WebPortal } from '@gk/gk-modules';
import { Observable } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { WebPortalService } from '../../services/web-portal/web-portal.service';

@Component({
  selector: 'app-free-services-portal',
  templateUrl: './free-services-portal.component.html',
  styleUrls: ['./free-services-portal.component.scss'],
})
export class FreeServicesPortalComponent implements OnInit {
  currentPortalRoute: Observable<WebPortal>;
  constructor(private webPortalService: WebPortalService) {}

  ngOnInit(): void {
    this.getCurrentPortalByRoute();
  }

  getCurrentPortalByRoute() {
    this.currentPortalRoute =
      this.webPortalService.getWebPortalDataByCurrentRoute(MainRoutes.Przp);
  }
}
