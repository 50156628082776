<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.INVESTOR_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <app-contact-data
      [submitted]="submitted"
      [contactDataFormGroup]="getContactDataFormGroup()"
    ></app-contact-data>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isConstructionIntentionTypeFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.ConstructionIntentionType"
          >
            {{
              'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE'
                | translate
            }}
          </label>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <mat-select
                class="form-select form-select-sm required"
                [id]="controlName.ConstructionIntentionType"
                [attr.name]="controlName.ConstructionIntentionType"
                [formControlName]="controlName.ConstructionIntentionType"
                [placeholder]="
                  'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE_PLACEHOLDER'
                    | translate
                "
                multiple
              >
                @for (
                  constructionIntentionType of singleFamilyBuildingConstructionIntentionTypeDictionaryService.get()
                    | async;
                  track constructionIntentionType.id
                ) {
                  <mat-option [value]="constructionIntentionType.id">
                    {{ constructionIntentionType.name }}
                  </mat-option>
                }
              </mat-select>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p class="text-muted mb-0">
            {{
              'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.CONSTRUCTION_INTENTION_TYPE_INFO'
                | translate
            }}
          </p>
          @if (!isConstructionIntentionTypeFormControlValid() && submitted) {
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          }
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isPlannedStartDateFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.PlannedStartDate">
            {{
              'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.CONSTRUCTION_WORKS_PLANNED_START_DATE'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <input
                  class="form-control form-control-sm"
                  [attr.placeholder]="
                    'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                  "
                  [id]="controlName.PlannedStartDate"
                  [name]="controlName.PlannedStartDate"
                  [formControlName]="controlName.PlannedStartDate"
                  ngbDatepicker
                  #plannedStartDateDatePicker="ngbDatepicker"
                />
                <button
                  class="btn btn-outline-secondary fa fa-calendar"
                  (click)="plannedStartDateDatePicker.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </div>
        </div>
        @if (!isPlannedStartDateFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-electronic-correspondence-statement
      [formGroup]="formGroup"
      [submitted]="submitted"
    >
    </app-electronic-correspondence-statement>

    <app-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </app-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'SINGLE_FAMILY_BUILDING_CONSTRUCTION_CONVERSION.NEW_REQUEST.SUBMIT'
            | translate
        }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
