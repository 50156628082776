import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import {
  filter,
  finalize,
  isObservable,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { BaseStyleClassesDirective } from '../base-style-classes/base-style-classes.directive';
import { RequestPreviewService } from './request-preview.service';

@Component({
  selector: 'app-request-preview-button',
  templateUrl: './request-preview-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./request-preview-button.component.scss'],
})
export class RequestPreviewButtonComponent<
  T,
> extends BaseStyleClassesDirective {
  @Input() getPreviewRequestBody: () => Observable<T> | T;
  @Input() previewRequestValidation?: () => boolean | Observable<boolean>;
  @Input() previewRequestUrl: string;
  downloadingPreviewFile = false;

  constructor(
    private fileSaverService: FileSaverService,
    private requestPreviewService: RequestPreviewService,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  previewRequest(): void {
    this.downloadingPreviewFile = true;

    const previewRequestValidationResult = this.previewRequestValidation
      ? this.previewRequestValidation()
      : true;

    (isObservable(previewRequestValidationResult)
      ? previewRequestValidationResult
      : of(previewRequestValidationResult)
    )
      .pipe(
        filter((isValid) => isValid),
        switchMap(() => {
          const previewRequestBodyResult = this.getPreviewRequestBody();
          return isObservable(previewRequestBodyResult)
            ? previewRequestBodyResult
            : of(previewRequestBodyResult);
        }),
        switchMap((previewRequestBody) =>
          this.requestPreviewService.previewNewRequest(previewRequestBody, {
            fileName: true,
            url: this.previewRequestUrl,
          }),
        ),
        finalize(() => {
          this.downloadingPreviewFile = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe({
        next: (response) => {
          this.fileSaverService.save(response.file, response.fileName);
        },
        error: (err) => {
          const message = _.get(err, 'error.ResponseStatus.Message', '');
          if (message) {
            this.toastr.error(message);
          }
        },
      });
  }
}
