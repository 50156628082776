import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  getContactDataFormGroup,
  getDefaultControlValue,
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  LegalPersonControlName,
  NaturalPersonControlName,
} from '@gk/gk-modules';
import { ECSControlName } from '../../components/electronic-correspondence-statement/models/form/form.model';
import { InvestorDetailsFormService } from '../../services/investor-details-form/investor-details-form.service';
import { ShippingFormService } from '../../services/shipping-form/shipping-form.service';
import {
  BaseNewRequestControlName,
  BaseNewRequestControlsExistenceParams,
  BaseNewRequestFormGroup,
  BaseNewRequestParams,
  getDefaultBaseNewRequestControlsExistenceParams,
  getDefaultBaseNewRequestParams,
  InvestorDetailsNewRequestFormGroup,
  LawPersonNewRequestFormGroup,
} from './base-new-request-form.model';

@Injectable({
  providedIn: 'root',
})
export class BaseNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private shippingFormService: ShippingFormService,
    private investorDetailsFormService: InvestorDetailsFormService,
  ) {}

  getCommonApplicantFormGroup(
    controlExistenceParams?: BaseNewRequestControlsExistenceParams,
    params?: BaseNewRequestParams,
  ): FormGroup<LawPersonNewRequestFormGroup> {
    const existenceParamsMergedWithDefault = {
      ...getDefaultBaseNewRequestControlsExistenceParams(),
      ...controlExistenceParams,
    };
    const paramsMergedWithDefault = {
      ...getDefaultBaseNewRequestParams(),
      ...params,
    };

    return this.formBuilder.group({
      ...(existenceParamsMergedWithDefault[BaseNewRequestControlName.LawPerson]
        ? {
            [BaseNewRequestControlName.LawPerson]: this.getLawPersonFormGroup(),
          }
        : {}),
      ...this.getCommonFormGroup(
        existenceParamsMergedWithDefault,
        paramsMergedWithDefault,
      ).controls,
    });
  }

  getCommonInvestorFormGroup(
    controlExistenceParams?: BaseNewRequestControlsExistenceParams,
    params?: BaseNewRequestParams,
  ): FormGroup<InvestorDetailsNewRequestFormGroup> {
    const existenceParamsMergedWithDefault = {
      ...getDefaultBaseNewRequestControlsExistenceParams(),
      ...controlExistenceParams,
    };
    const paramsMergedWithDefault = {
      ...getDefaultBaseNewRequestParams(),
      ...params,
    };

    return this.formBuilder.group({
      ...(existenceParamsMergedWithDefault[
        BaseNewRequestControlName.InvestorDetails
      ]
        ? {
            [BaseNewRequestControlName.InvestorDetails]:
              this.investorDetailsFormService.getFormGroup(
                paramsMergedWithDefault.isInvestorPhoneNumberRequired,
                paramsMergedWithDefault.isInvestorPhoneNumberValid,
                paramsMergedWithDefault.investorPrimitivePostalCode,
                paramsMergedWithDefault.isInvestorEmailRequired,
              ),
          }
        : {}),

      ...this.getCommonFormGroup(
        existenceParamsMergedWithDefault,
        paramsMergedWithDefault,
      ).controls,
    });
  }

  getCommonFormGroup(
    controlExistenceParams?: BaseNewRequestControlsExistenceParams,
    params?: BaseNewRequestParams,
  ): FormGroup<BaseNewRequestFormGroup> {
    const allControlConfigFields: {
      [key in BaseNewRequestControlName]?: any;
    } & {
      [key in ECSControlName]?: any;
    } = {
      [BaseNewRequestControlName.ProxyDetailsCheckbox]:
        this.formBuilder.control(getDefaultControlValue()),
      [BaseNewRequestControlName.DocumentationForm]: [
        params?.documentationFormType,
        [Validators.required],
      ],
      [BaseNewRequestControlName.ShippingForm]:
        this.shippingFormService.getFormGroup(
          params?.disableShippingEmail,
          params?.shippingPrimitivePostalCode,
        ),
      [BaseNewRequestControlName.ContactData]: getContactDataFormGroup(),
      [ECSControlName.ElectronicCorrespondenceStatement]: [
        undefined,
        [Validators.required],
      ],
    };
    const controlConfig = Object.keys(allControlConfigFields).reduce(
      (acc, key) => {
        const typedKey = key as BaseNewRequestControlName | ECSControlName;
        if (
          controlExistenceParams[typedKey as BaseNewRequestControlName] &&
          typedKey in allControlConfigFields
        ) {
          acc[typedKey] = allControlConfigFields[typedKey];
        }
        return acc;
      },
      {} as Record<string, any>,
    );
    return this.formBuilder.group(controlConfig);
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group({
        [LegalPersonControlName.TypeId]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LegalPersonControlName.Name]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(),
          Validators.required,
        ],
      }),
      [LawPersonControlName.Address]: this.getAddressFormGroup(),
    });
  }

  getAddressFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonAddressControlName.Place]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LawPersonAddressControlName.Street]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LawPersonAddressControlName.StreetAbsence]: false,
      [LawPersonAddressControlName.BuildingNumber]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
      [LawPersonAddressControlName.PostalCode]: [
        getDefaultControlValue(),
        Validators.required,
      ],
      [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
    });
  }

  getFirstAndLastNameFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
      }),
    });
  }
}
