import { BaseNewRequestControlName } from '../../../../shared/base-new-request-form/base-new-request-form.model';

export enum SPPNewRequestAdditionalControlName {
  Place = 'place',
  Street = 'street',
  Comments = 'comments',
}

export const SPPNewRequestControlName = {
  ...BaseNewRequestControlName,
  ...SPPNewRequestAdditionalControlName,
};
