import { ApiNewDokumentPowiazanyDalDto, LawPerson } from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
    DocumentationFormType,
    InvestorRequest,
    InvestorRequestDto,
} from '../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../utils/date-helper/date-helper.util';

export interface BPMDNewRequestDto extends InvestorRequestDto {
  DataWydaniaDecyzji: string;
  NrDecyzji: string;
  SygnaturaSprawy: string;
  NazwaZamierzeniaBudowlanego: string;
}

export class BPMDDecisionInformation {
  constructor(
    public publicationDate: NgbDate,
    public decisionNumber: string,
    public caseNumber: string,
    public constructionProjectName: string,
  ) {}
}

export class BPMDNewRequest extends InvestorRequest {
  constructor(
    lawPerson: LawPerson,
    contactData: LawPerson,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public decisionInformation: BPMDDecisionInformation,
    electronicCorrespondenceStatement: boolean,
    proxyDetails: InvestorDetails,
    proxyDetailsCheckbox: boolean,
  ) {
    super(
      lawPerson,
      contactData,
      documentationForm,
      shippingForm,
      electronicCorrespondenceStatement,
      proxyDetails,
      proxyDetailsCheckbox,
    );
  }

  static fromAppToApi(
    data: BPMDNewRequest,
    files: ApiNewDokumentPowiazanyDalDto[],
  ): BPMDNewRequestDto {
    return {
      ...InvestorRequest.mapCommonFields({
        data,
        files,
      }),
      DataWydaniaDecyzji: DateHelperUtil.ngbDateToApiDateTime(
        data.decisionInformation.publicationDate,
      ),
      NrDecyzji: data.decisionInformation.decisionNumber,
      SygnaturaSprawy: data.decisionInformation.caseNumber,
      NazwaZamierzeniaBudowlanego:
        data.decisionInformation.constructionProjectName,
    };
  }
}
