import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { GkComponentsModule, GkMapModule } from "@gk/gk-modules";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../../shared/shared.module";
import { createTranslateLoader } from "../address-number-request-portal/address-number-request-portal.module";
import { LandAndBuildingRegisterDataUpdateUponEntitiesRequestRoutingModule } from "./land-and-building-register-data-update-upon-entities-request-routing.module";
import { LandAndBuildingRegisterDataUpdateUponEntitiesRequestComponent } from "./land-and-building-register-data-update-upon-entities-request.component";
import { LBRDUUERNewDataUpdateRequestComponent } from "./new-data-update-request/new-data-update-request.component";
import { LBRDUUERNewDataUpdateRequestFormService } from "./services/new-data-update-request-form/new-data-update-request-form.service";

@NgModule({
  declarations: [
    LandAndBuildingRegisterDataUpdateUponEntitiesRequestComponent,
    LBRDUUERNewDataUpdateRequestComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GkMapModule,
    GkComponentsModule,
    LandAndBuildingRegisterDataUpdateUponEntitiesRequestRoutingModule,
  ],
  providers: [LBRDUUERNewDataUpdateRequestFormService],
})
export class LandAndBuildingRegisterDataUpdateUponEntitiesRequestModule {}
