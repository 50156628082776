import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColumnHeader,
  DocSignService,
  FormAlertService,
  MapAction,
  MapExtentUtils,
  MapId,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  MapViewActionType,
  PortalId,
  PzService,
  ShapeUtils,
  SourceType,
  StreetService,
  ToolType,
  getFileLoaderFormFieldConfigs,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, from, takeWhile } from 'rxjs';
import { MainRoutes } from '../../../guards/guard.models';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import {
  BsMessageType,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
} from '../../../services/request-workspace-state/request-workspace-state.model';
import { SessionService } from '../../../services/session/session.service';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import {
  GACSDRNewRequestControlName,
  GACSDRNewRequestFormGroup,
} from '../services/new-request-form/new-request-form.model';
import { GACSDRNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import {
  GACSDRNewRequest,
  GACSDRNewRequestDto,
  GACSDRNewRequestFormWorkspaceState,
} from '../services/new-request/new-request.model';
import { WorkPurposeDictionaryService } from '../services/work-purpose-dictionary/work-purpose-dictionary.service';

@Component({
  selector: 'app-gacsdr-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class GACSDRNewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = GACSDRNewRequestControlName;
  override formGroup: FormGroup<GACSDRNewRequestFormGroup>;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.ExtentOrPolygon],
    true,
  );
  mapSettings: MapSettings;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  successSigned = false;
  isArchivalWorkPurposeVisible = true;
  override portalId = PortalId.GeodeticAndCartographicServiceDataRequest;
  workspaceStateDraftVersion =
    WorkspaceStateDraftVersion.GeodeticAndCartographicServiceDataRequestNewRequestFormVersion;
  workspaceStateId =
    WorkspaceStateId.GeodeticAndCartographicServiceDataRequestNewRequestForm;
  downloadingPreviewFile = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private gacsdrNewRequestFormService: GACSDRNewRequestFormService,
    public override streetService: StreetService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
    public workPurposeDictionaryService: WorkPurposeDictionaryService,
    public sessionService: SessionService,
    public override fileSaverService: FileSaverService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.subscribeToDocSignTranslations();
    this.createForm();
    this.fetchMapSettings();
  }

  createForm(): void {
    this.formGroup = this.gacsdrNewRequestFormService.getFormGroup();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.GeodeticAndCartographicServiceDataRequest,
      this.mapStateService.getViewState(
        MapId.GeodeticAndCartographicServiceDataRequest,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      {
        ...this.initialToolsState,
        [ToolType.AnyPolygon]: {
          ...this.initialToolsState[ToolType.AnyPolygon],
          [SourceType.File]: {
            ...this.initialToolsState[ToolType.AnyPolygon][SourceType.File],
            fieldConfigs: getFileLoaderFormFieldConfigs(true),
          },
        },
      },
      this.mapStateService.getLayersState(
        MapId.GeodeticAndCartographicServiceDataRequest,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return this.formGroup.dirty || this.areWorkRangesChosen();
  }

  handleSubmit(): void {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }

    this.sendRequest();
  }

  isRequestValid(): boolean {
    return this.formGroup.valid && this.areWorkRangesChosen();
  }

  areWorkRangesChosen(): boolean {
    return !!this.mapState.mapObjectTablesState[0].mapObjects?.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
    );
  }

  sendRequest(): void {
    this.docSignPending = true;
    this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');

    this.docSignService
      .addToSign(
        this.getRequestBody(),
        '/api/interesant/wniosek/udostMatSluzbieGeodezyjnej/addToSign',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (error) => this.handleSendAndValidateFailure(error),
      });
  }

  getWorkspaceStateToSave = (): GACSDRNewRequestFormWorkspaceState => ({
    formValue: this.getFormValue(),
    workRanges: this.getWorkRanges(),
  });

  handleLoadWorkspaceResponse(state: GACSDRNewRequestFormWorkspaceState): void {
    this.formGroup.patchValue(state.formValue);
    const mapObjects = state.workRanges.map((workRange) =>
      ShapeUtils.getAnyGeometryMapObject(
        workRange,
        MapObjectApiType.ExtentOrPolygon,
      ),
    );
    this.handleMapAction(
      new MapAction(MapObjectTableActionType.AddNew, mapObjects),
    );
    this.handleMapAction(
      new MapAction(
        MapViewActionType.ExtentToFitToChange,
        MapExtentUtils.getMapExtentFromMapObjects(mapObjects),
      ),
    );
    this.handleMapAction(
      new MapAction(MapObjectTableActionType.SelectRange, mapObjects),
    );
  }

  getWorkRanges(): string[] {
    return [...(this.mapState.mapObjectTablesState[0].mapObjects || [])]
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((workRange) => workRange.geom);
  }

  getFormValue(): GACSDRNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.GeodeticAndCartographicServiceDataRequest}/requests-list`,
    );
  }

  getRequestBody = (): GACSDRNewRequestDto =>
    GACSDRNewRequest.fromAppToApi(this.getFormValue(), this.getWorkRanges());

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
