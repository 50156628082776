import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import * as olProj from 'ol/proj';
import { takeWhile } from 'rxjs';
import { CityData, SystemCitiesDataService } from '../../../../../services';
import { ProjectionCode } from '../../../../models';
import { CoordinationLayerResponse } from '../../../../services/map-requests/map-request.model';

@Component({
  selector: 'gk-map-mouse-position',
  templateUrl: './map-mouse-position.component.html',
  styleUrls: ['./map-mouse-position.component.scss'],
})
export class MapMousePositionComponent implements OnChanges, OnDestroy, OnInit {
  @Input() currentCoordinates!: Coordinate;
  @Input() coordinationLayers: CoordinationLayerResponse[] = [];

  systemCityData: CityData;
  currentCoordinatesParsed!: Coordinate;
  currentCoordinationLayer?: CoordinationLayerResponse;

  private isAlive = true;

  constructor(private systemCitiesDataService: SystemCitiesDataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentCoordinates']?.currentValue) {
      this.updateParsedCoordinates();
    }

    if (
      changes['coordinationLayers']?.currentValue &&
      !this.currentCoordinationLayer
    ) {
      this.setInitialCoordinationLayer();
    }
  }

  onValueChange(selectedLayer: CoordinationLayerResponse): void {
    this.currentCoordinationLayer = selectedLayer;
    this.updateParsedCoordinates();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  ngOnInit(): void {
    this.fetchSystemCitiesData();
  }

  private fetchSystemCitiesData(): void {
    this.systemCitiesDataService.systemCitiesData
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (systemCitiesData) => (this.systemCityData = systemCitiesData[0]),
      );
  }

  private updateParsedCoordinates(): void {
    if (this.currentCoordinates && this.currentCoordinationLayer) {
      this.currentCoordinatesParsed =
        this.transformCoordinateToSelectedDestination(
          this.currentCoordinates,
        ).map((coord) => parseFloat(coord.toFixed(2))) as Coordinate;
    }
  }

  private setInitialCoordinationLayer(): void {
    if (this.coordinationLayers.length > 0) {
      this.currentCoordinationLayer = this.coordinationLayers[0];
    }
  }

  private transformCoordinateToSelectedDestination(
    coordinateToTransform: Coordinate,
  ): Coordinate {
    if (!this.currentCoordinationLayer) {
      throw new Error('Current coordination layer is not defined');
    }
    return olProj.transform(
      coordinateToTransform,
      ProjectionCode.PseudoMercator,
      `EPSG:${this.currentCoordinationLayer.epsg}`,
    );
  }
}
