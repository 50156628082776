import { Component } from '@angular/core';
import { BaseDetailsComponent } from '../../../../gk-kendo';
import {
  BuildingDetails,
  BuildingDetailsDto,
} from './map-building-details.model';

@Component({
  selector: 'gk-map-building-details',
  templateUrl: '../../../../gk-kendo/base-details/base-details.component.html',
})
export class MapBuildingDetailsComponent extends BaseDetailsComponent<BuildingDetailsDto> {
  override parseCallback = BuildingDetails.fromApiToApp;

  constructor() {
    super();
  }

  loadData(data: BuildingDetailsDto): void {
    this.loadDetailsWithData(data);
  }
}
