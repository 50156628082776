import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MapControl } from '../../../../controls';
import {
  FeatureCollectionType,
  GkFeature,
  MapGeometryCollectionsWithWktType,
  MapGeometryStyleConfig,
  MapLineString,
  MapObject,
  MapPoint,
  MapPolygon,
  ProjectionCode,
  SourceType,
  WktType,
} from '../../../../models';
import { ConversionUtils } from '../../../../utils';
import { FeatureCollection, Position } from 'geojson';

@Component({
  selector: 'gk-map-geometry',
  templateUrl: './map-geometry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapGeometryComponent extends MapControl {
  @Input()
  mapObjects: MapObject[] | any;
  @Input()
  styles: MapGeometryStyleConfig;
  @Input()
  projectionCode: ProjectionCode;
  @Input()
  source: SourceType | string;
  wktTypeEnum = WktType;
  FeatureCollectionType = FeatureCollectionType;

  getGeometryCollectionWithWktType(
    mapObject: MapObject,
  ): MapGeometryCollectionsWithWktType {
    const wktType = ConversionUtils.getWktType(mapObject.geom);

    return {
      mapGeometryCollection:
        ConversionUtils.getMapGeometryCollectionBasedOnWktType(
          mapObject.geom,
          wktType,
        ),
      wktType,
    };
  }

  getMapGeometryFromGeoJson(geoJson: FeatureCollection[]): GkFeature[] {
    const features = geoJson[0]?.features || [];
    return features.map((feature: any, index: number) => {
      return {
        geometry: feature.geometry,
        type: geoJson[0].type,
        id: `${this.source}-${feature.id || index}`,
        styles: {
          featureStyle: {
            strokeColor: feature.properties.style.stroke_.color_,
            strokeWidth: feature.properties.style.stroke_.width_,
          },
        } as MapGeometryStyleConfig,
      };
    });
  }

  getGeometryId(mapObject: MapObject, feat: number, part: number): string {
    return `${this.source}-${mapObject.uuid}-${mapObject.type}-${feat}-${part}`;
  }

  getFeatureId(feature: GkFeature, feat: number): string {
    return `${this.source}-${feature.id}-${feature.type}-${feat}`;
  }

  getPoint(geometry: MapPolygon | MapPoint | MapLineString): MapPoint {
    return <MapPoint>geometry;
  }

  getFeaturePoint(
    geometry: Position | Position[] | Position[][] | Position[][][],
  ): MapPoint {
    return geometry as MapPoint;
  }

  getLineString(
    geometry: MapPolygon | MapPoint | MapLineString,
  ): MapLineString {
    return <MapLineString>geometry;
  }

  getLineStringFromFeature(
    geometry: Position | Position[] | Position[][] | Position[][][],
  ): MapLineString {
    return geometry as MapLineString;
  }

  getPolygon(geometry: MapPolygon | MapPoint | MapLineString): MapPolygon {
    return <MapPolygon>geometry;
  }

  getPolygonFromFeature(
    geometry: Position | Position[] | Position[][] | Position[][][],
  ): MapPolygon {
    return geometry as MapPolygon;
  }
}
