<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'DEMOLITION_PERMIT.NEW_REQUEST.INVESTOR_DATA' | translate }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <app-contact-data
      [submitted]="submitted"
      [contactDataFormGroup]="getContactDataFormGroup()"
    ></app-contact-data>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areBuildingsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{ 'DEMOLITION_PERMIT.NEW_REQUEST.LOCATION' | translate }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          @if (
            mapState.mapObjectTablesState[parcelsMapObjectTableStateIndex]
              ?.mapObjects?.length
          ) {
            <h4 class="mt-4" tabindex="0" aria-required="true">
              {{ 'DEMOLITION_PERMIT.NEW_REQUEST.CHOSEN_PARCELS' | translate }}
            </h4>
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="parcelsMapObjectTableStateIndex"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }

          @if (
            mapState.mapObjectTablesState[buildingsMapObjectTableStateIndex]
              ?.mapObjects?.length
          ) {
            <h4 class="mt-2" tabindex="0">
              {{ 'DEMOLITION_PERMIT.NEW_REQUEST.CHOSEN_BUILDINGS' | translate }}
            </h4>
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="buildingsMapObjectTableStateIndex"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }
        </div>
        @if (submitted && !areBuildingsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'DEMOLITION_PERMIT.NEW_REQUEST.BUILDING_REQUIRED' | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isDemolitionInformationFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.DemolitionInformation">
            {{
              'DEMOLITION_PERMIT.NEW_REQUEST.DEMOLITION_INFORMATION' | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <textarea
                [name]="controlName.DemolitionInformation"
                [id]="controlName.DemolitionInformation"
                class="form-control form-control-sm required"
                aria-required="true"
                [formControlName]="controlName.DemolitionInformation"
              ></textarea>
            </div>
          </div>
        </div>

        @if (!isDemolitionInformationFormControlValid() && submitted) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-electronic-correspondence-statement
      [formGroup]="formGroup"
      [submitted]="submitted"
    >
    </app-electronic-correspondence-statement>

    <app-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </app-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'DEMOLITION_PERMIT.NEW_REQUEST.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{ 'DEMOLITION_PERMIT.NEW_REQUEST.SUBMIT' | translate }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
