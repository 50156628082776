import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  getContactDataFormGroup,
  getDefaultControlValue,
} from '@gk/gk-modules';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { GRPPortal } from '../portals/portals.model';
import {
  GRPNewGrantRequestControlName,
  GRPNewGrantRequestFormGroup,
  GRPPermissionControlName,
  GRPPermissionFormGroup,
} from './new-grant-request-form.model';

@Injectable()
export class GRPNewGrantRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<GRPNewGrantRequestFormGroup> {
    return this.formBuilder.group({
      [GRPNewGrantRequestControlName.LawPerson]:
        this.baseNewRequestFormService.getFirstAndLastNameFormGroup(),
      [GRPNewGrantRequestControlName.ContactData]: getContactDataFormGroup(),
      [GRPNewGrantRequestControlName.Permission]: this.formBuilder.array([]),
      [GRPNewGrantRequestControlName.PortalIds]: [
        getDefaultControlValue(),
        Validators.required,
      ],
    });
  }

  getPermissionFormGroup(item: GRPPortal): FormGroup<GRPPermissionFormGroup> {
    return this.formBuilder.group({
      [GRPPermissionControlName.PortalName]: item.name,
      [GRPPermissionControlName.PortalId]: item.id,
      [GRPPermissionControlName.PermissionNumber]: [
        getDefaultControlValue(!item.requiredDocumentTypeId),
        item.requiredDocumentTypeId ? Validators.required : null,
      ],
      [GRPPermissionControlName.RequiredDocumentTypeId]:
        item.requiredDocumentTypeId,
      [GRPPermissionControlName.IsPermissionNumberRequired]:
        item.isPermissionNumberRequired,
    });
  }
}
