import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  LawPerson,
  Place,
  Street,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface SPPNewRequestDto extends ApplicantRequestDto {
  MiejscId: string | number;
  Miejsc: string;
  UlicaId: string | number;
  Ulica: string;
  InfoDodatkowe: string;
}

export class SPPNewRequest extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public place: Place,
    public street: Street,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public comments: string,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: SPPNewRequest,
    issueRanges: string[],
    parcels: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): SPPNewRequestDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        issueRanges,
        parcels,
        files,
      }),
      MiejscId: data.place.id,
      Miejsc: data.place.name,
      UlicaId: data.street && data.street.id,
      Ulica: data.street && data.street.name,
      InfoDodatkowe: data.comments,
    };
  }
}
