import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { getDefaultControlValue } from '@gk/gk-modules';
import { BaseNewRequestFormService } from '../../../../shared/base-new-request-form/base-new-request-form.service';
import { DocumentationFormType } from '../../../../shared/base-new-request/base-new-request.model';
import {
  BPNewRequestControlName,
  BPNewRequestFormGroup,
} from './new-request-form.model';

@Injectable()
export class BPNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public baseNewRequestFormService: BaseNewRequestFormService,
  ) {}

  getFormGroup(): FormGroup<BPNewRequestFormGroup> {
    return this.formBuilder.group({
      ...this.baseNewRequestFormService.getCommonApplicantFormGroup(
        {
          [BPNewRequestControlName.ContactData]: true,
        },
        {
          documentationFormType: DocumentationFormType.NonElectronicForm,
        },
      ).controls,
      [BPNewRequestControlName.ConstructionIntentionType]: [
        [],
        [Validators.required],
      ],
      [BPNewRequestControlName.ConstructionProjectName]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
    });
  }
}
