import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  DepartmentCode,
  LinkNavItemConfig,
  NavItemsSectionConfig,
  SkipLinkItem,
  SkipLinksConfiguration,
} from '@gk/gk-modules';
import { filter, map, of, takeWhile } from 'rxjs';
import { MainRoutes } from '../../guards/guard.models';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { OfficeDepartmentsService } from '../../services/office-departments/office-departments.service';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import { AccessibleNavigationService } from './accessible-navigation.service';

@Component({
  selector: 'app-accessible-navigation',
  templateUrl: './accessible-navigation.component.html',
  styleUrls: ['./accessible-navigation.component.scss'],
})
export class AccessibleNavigationComponent implements OnInit, OnDestroy {
  public skipLinksConfigurationArray: SkipLinksConfiguration[];
  public currentSkipLinksConfiguration: SkipLinksConfiguration = null;
  public isAlive = true;
  public masterId: number;
  public departmentCode: DepartmentCode;

  constructor(
    private router: Router,
    private officeDepartmentsService: OfficeDepartmentsService,
    private webPortalService: WebPortalService,
    private accessibleNavigationService: AccessibleNavigationService,
    private appTitleService: AppTitleService,
  ) {}

  ngOnInit(): void {
    this.initSkipLinksConfiguration();
    this.subscribeToRouterEvents();
    this.subscribeForNavbarSkipLinks();
  }

  subscribeForNavbarSkipLinks(): void {
    this.appTitleService.currentPortalRoute
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((portalRoute) => {
        const navItemsSectionConfig =
          this.accessibleNavigationService.handlePortalTypeChange(portalRoute);

        if (navItemsSectionConfig.length > 0) {
          this.currentSkipLinksConfiguration = this.createSkipLinksFoNavBar(
            portalRoute,
            navItemsSectionConfig,
          );
        }
      });
  }

  subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeWhile(() => this.isAlive),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event) => {
        const navEndEvent = event as NavigationEnd;
        this.setCurrentSkipLinksIfPossible();
        if (this.accessibleNavigationService.isCurrentPathDepartmentPath()) {
          this.handleDepartmenRoute(navEndEvent.urlAfterRedirects);
        }
      });
  }

  handleDepartmenRoute(routeUrl: string): void {
    const departmentCode =
      this.accessibleNavigationService.getDepartmentCodeFromCurrentUrl();
    this.currentSkipLinksConfiguration = this.createSkipLinksFoRequestList(
      <DepartmentCode>departmentCode,
      routeUrl,
    );
  }

  initSkipLinksConfiguration(): void {
    this.skipLinksConfigurationArray = this.createSkipLinksConfiguration();
    this.setCurrentSkipLinksIfPossible();
  }

  setCurrentSkipLinksIfPossible(): void {
    this.currentSkipLinksConfiguration =
      this.skipLinksConfigurationArray.find((config) =>
        this.isCurrentPathMatching(config.routingUrlToShowSkipLink),
      ) || null;
  }

  createSkipLinksConfiguration(): SkipLinksConfiguration[] {
    return [
      this.createSkipLinksForOfficeDepartments(),
      this.createSkipLinksForGeoDepartment(),
    ];
  }

  createSkipLinksForOfficeDepartments(): SkipLinksConfiguration {
    return new SkipLinksConfiguration(
      MainRoutes.OfficeDepartmentsPortalHub,
      this.officeDepartmentsService.officeDepartments.pipe(
        map((departments) =>
          departments.map(
            (department) =>
              new SkipLinkItem(department.name, `#${department.code}`),
          ),
        ),
      ),
    );
  }

  createSkipLinksForGeoDepartment(): SkipLinksConfiguration {
    return new SkipLinksConfiguration(
      MainRoutes.GeoDepartment,
      this.webPortalService
        .getGeodesyWebPortals()
        .pipe(
          map((webPortals) =>
            webPortals.map(
              (webPortal) =>
                new SkipLinkItem(webPortal.name, `#${webPortal.portalId}`),
            ),
          ),
        ),
    );
  }

  createSkipLinksFoRequestList(
    departmentCode: DepartmentCode,
    requestListUrl: string,
  ): SkipLinksConfiguration {
    return new SkipLinksConfiguration(
      requestListUrl,
      this.accessibleNavigationService
        .getDepartmentRequestsByCode(departmentCode)
        .pipe(
          map((webPortals) =>
            webPortals.map(
              (webPortal) =>
                new SkipLinkItem(
                  webPortal.name,
                  `#portal-${webPortal.portalId}`,
                ),
            ),
          ),
        ),
    );
  }

  createSkipLinksFoNavBar(
    portalRoute: MainRoutes,
    navItemsSectionConfig: NavItemsSectionConfig[],
  ): SkipLinksConfiguration {
    return new SkipLinksConfiguration(
      portalRoute,
      of(navItemsSectionConfig[0].navItemConfigs).pipe(
        map((navItemsSectionConfig) =>
          navItemsSectionConfig.map(
            (navItemSectionConfig) =>
              new SkipLinkItem(
                (navItemSectionConfig as LinkNavItemConfig).linkLabel,
                `#${navItemSectionConfig.navItemId}`,
              ),
          ),
        ),
      ),
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  private isCurrentPathMatching(routingUrl: string): boolean {
    return window.location.pathname.endsWith(routingUrl);
  }
}
