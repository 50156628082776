import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  ColumnHeader,
  DocSignService,
  EgibObject,
  FormAlertService,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, switchMap, takeWhile, tap } from 'rxjs';
import { ProxyDetailsComponent } from '../../../components/proxy-details/proxy-details.component';
import { MainRoutes } from '../../../guards/guard.models';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { InvestorDetailsComponent } from '../../../shared/investor-details/investor-details.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import { DocsTableEditableComponent } from '../../designer-portal/new-designer-request/docs-table-editable/docs-table-editable.component';
import {
  FellingObjectsControlName,
  FellingPurpose,
  TASFNewRequestControlName,
} from './services/new-request-form/new-request-form.model';
import { TASFNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { TASFNewRequest } from './services/new-request/new-request.model';

@Component({
  selector: 'app-trees-and-shrubs-felling',
  templateUrl: './trees-and-shrubs-felling-request.component.html',
})
export class TreesAndShrubsFellingRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  @ViewChild(InvestorDetailsComponent)
  investorDetailsComponent: InvestorDetailsComponent;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(ProxyDetailsComponent)
  proxyDetailsComponent: ProxyDetailsComponent;
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  override controlName = TASFNewRequestControlName;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('area', 'GK.MAP.AREA_IN_HA'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  formValue: TASFNewRequest;
  formNotValidTranslation: string;
  fellingObjectsControlName = FellingObjectsControlName;
  fellingPurpose = FellingPurpose;
  override portalId =
    PortalId.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest;

  constructor(
    private tasfNewRequestFormService: TASFNewRequestFormService,
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    private toastr: ToastrService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    public override router: Router,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override streetService: StreetService,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    super.subscribeToDocSignTranslations();
    this.subscribeToFormNotValidTranslation();
    this.fetchApplicantMapSettings();
    this.fetchWrongFileText();
  }

  createForm(): void {
    this.formGroup = this.tasfNewRequestFormService.getFormGroup();
    this.disableOrEnableStreetFormControlOnStreetAbsenceCheckboxChange();
  }

  subscribeToFormNotValidTranslation(): void {
    this.translateService
      .get(
        'ENVIRONMENTAL_PROTECTION_PORTAL.TREES_AND_SHRUBS_FELLING.VALIDATION.FORM_NOT_VALID_ERROR',
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (formNotValidTranslation) =>
          (this.formNotValidTranslation = formNotValidTranslation),
      );
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewEnvironmentalProtectionPortalRequest,
      this.mapStateService.getViewState(
        MapId.NewEnvironmentalProtectionPortalRequest,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewEnvironmentalProtectionPortalRequest,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.mapObjectTableState],
    );
  }

  areParcelsChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.LandParcel,
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestValid()) {
      this.toastr.error(this.formNotValidTranslation);
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    forkJoin([
      this.investorDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
      this.proxyDetailsComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
    ])
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            TASFNewRequest.fromAppToApi(
              this.getFormValue(),
              this.getParcelMapObject(),
              this.getIssueRangesMapObjects(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/wycinkaDrzewIKrzewow/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  override handleDocSignSuccess(): void {
    this.docSignUrl = '';
    this.router.navigateByUrl(
      `/${MainRoutes.EnvironmentalProtectionPortalTreesAndShrubsFellingRequest}/requests-list`,
    );
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.areParcelsChosen() &&
      this.areIssueRangesChosen() &&
      this.attachmentsComponent.areDocumentsValid()
    );
  }

  areIssueRangesChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
    );
  }

  getFormValue(): TASFNewRequest {
    return this.formGroup.getRawValue();
  }

  getParcelMapObject(): EgibObject[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || []).filter(
      <(mapObject: MapObject) => mapObject is EgibObject>(
        ((mapObject) => mapObject.type === MapObjectApiType.LandParcel)
      ),
    );
  }

  getIssueRangesMapObjects(): string[] {
    return (this.mapState.mapObjectTablesState[0].mapObjects || [])
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((mapObject) => mapObject.geom);
  }

  checkParcelsAndIssueRangeValidAndFormValidated(): boolean {
    return (
      (!this.areParcelsChosen() || !this.areIssueRangesChosen()) &&
      this.submitted
    );
  }

  checkTreeFormGroupValidAndFormValidated(index: number): boolean {
    return !this.getTreeFormGroup(index).valid && this.submitted;
  }

  getTreeInventoryNumberFormControl(index: number): UntypedFormControl {
    return this.getTreeFormGroup(index).get(
      FellingObjectsControlName.InventoryNumber,
    ) as UntypedFormControl;
  }

  getTreeSpeciesNameFormControl(index: number): UntypedFormControl {
    return this.getTreeFormGroup(index).get(
      FellingObjectsControlName.SpeciesName,
    ) as UntypedFormControl;
  }

  getTreeTrunkCircumferenceFormControl(index: number): UntypedFormControl {
    return this.getTreeFormGroup(index).get(
      FellingObjectsControlName.TrunkCircumference,
    ) as UntypedFormControl;
  }

  getTreeFormGroup(index: number): UntypedFormGroup {
    return this.getTreesListFormArray().controls[index] as UntypedFormGroup;
  }

  deleteTreeFormControl(index: number): void {
    this.getTreesListFormArray().removeAt(index);
  }

  areSomeTreesOrShrubsFormGroups(): boolean {
    return this.areSomeTreesFormGroups() || this.areSomeShrubsFormGroups();
  }

  areSomeTreesFormGroups(): boolean {
    return !!this.getTreesListFormArray().controls.length;
  }

  areSomeShrubsFormGroups(): boolean {
    return !!this.getShrubsListFormArray().controls.length;
  }

  addTreeFormControl(): void {
    this.getTreesListFormArray().push(
      this.tasfNewRequestFormService.getTreeFormGroup(),
    );
  }

  getTreesListFormArray(): UntypedFormArray {
    return this.formGroup.get(
      TASFNewRequestControlName.TreesList,
    ) as UntypedFormArray;
  }

  checkShrubFormGroupValidAndFormValidated(index: number): boolean {
    return !this.getShrubFormGroup(index).valid && this.submitted;
  }

  getShrubInventoryNumberFormControl(index: number): UntypedFormControl {
    return this.getShrubFormGroup(index).get(
      FellingObjectsControlName.InventoryNumber,
    ) as UntypedFormControl;
  }

  getShrubSpeciesNameFormControl(index: number): UntypedFormControl {
    return this.getShrubFormGroup(index).get(
      FellingObjectsControlName.SpeciesName,
    ) as UntypedFormControl;
  }

  getShrubProjectionSurfaceFormControl(index: number): UntypedFormControl {
    return this.getShrubFormGroup(index).get(
      FellingObjectsControlName.ProjectionSurface,
    ) as UntypedFormControl;
  }

  getShrubFormGroup(index: number): UntypedFormGroup {
    return this.getShrubsListFormArray().controls[index] as UntypedFormGroup;
  }

  deleteShrubFormControl(index: number): void {
    this.getShrubsListFormArray().removeAt(index);
  }

  addShrubFormControl(): void {
    this.getShrubsListFormArray().push(
      this.tasfNewRequestFormService.getShrubFormGroup(),
    );
  }

  getShrubsListFormArray(): UntypedFormArray {
    return this.formGroup.get(
      TASFNewRequestControlName.ShrubsList,
    ) as UntypedFormArray;
  }

  checkFellingCauseFormControlValidAndFormValidated(): boolean {
    return !this.getFellingCauseFormControl().valid && this.submitted;
  }

  getFellingCauseFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.FellingCause,
    ) as UntypedFormControl;
  }

  checkFellingDateFormControlValidAndFormValidated(): boolean {
    return !this.getFellingDateFormControl().valid && this.submitted;
  }

  getFellingDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.FellingDate,
    ) as UntypedFormControl;
  }

  checkInvestmentDescriptionFormControlValidAndFormValidated(): boolean {
    return !this.getInvestmentDescriptionFormControl().valid && this.submitted;
  }

  getInvestmentDescriptionFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.InvestmentDescription,
    ) as UntypedFormControl;
  }

  checkGrowthPlaceFormControlValidAndFormValidated(): boolean {
    return !this.getGrowthPlaceFormControl().valid && this.submitted;
  }

  getGrowthPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.GrowthPlace,
    ) as UntypedFormControl;
  }

  checkBusinessPurposeFormControlValidAndFormValidated(): boolean {
    return !this.getBusinessPurposeFormControl().valid && this.submitted;
  }

  getBusinessPurposeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.BusinessPurpose,
    ) as UntypedFormControl;
  }

  isLocationFormGroupValid(): boolean {
    return (
      this.isLandRegisterFormControlValid() &&
      this.isPlaceFormControlValid() &&
      this.isStreetFormControlValid()
    );
  }

  isStreetFormControlValid(): boolean {
    const streetFormControl = this.getStreetFormControl();
    return streetFormControl.disabled || streetFormControl.valid;
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  override getPlaceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.Place,
    ) as UntypedFormControl;
  }

  isLandRegisterFormControlValid(): boolean {
    return this.getLandRegisterFormControl().valid;
  }

  getLandRegisterFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.LandRegister,
    ) as UntypedFormControl;
  }

  getLandNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.LandNumber,
    ) as UntypedFormControl;
  }

  disableOrEnableStreetFormControlOnStreetAbsenceCheckboxChange(): void {
    const streetAbsenceFormControl = this.getStreetAbsenceFormControl();
    if (!streetAbsenceFormControl) {
      return;
    }
    const streetFormControl = this.getStreetFormControl();
    streetAbsenceFormControl.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (value) {
          streetFormControl.disable();
        } else if (this.getStreetAbsenceFormControl().enabled) {
          streetFormControl.enable();
        }
      });
  }

  getStreetAbsenceFormControl(): UntypedFormControl {
    return this.formGroup.get(
      TASFNewRequestControlName.StreetAbsence,
    ) as UntypedFormControl;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
