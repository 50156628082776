import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiNewDokumentPowiazanyDalDto,
  auxiliaryLandParcelToolState,
  ColumnHeader,
  DocSignService,
  EgibObject,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  SourceType,
  ToolType,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { switchMap, takeWhile, tap } from 'rxjs';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestControlName } from '../../../shared/base-new-request-form/base-new-request-form.model';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import { ANRNewRequestFormService } from '../services/new-request-form/new-request-form.service';
import { ANRNewRequest } from '../services/new-request/new-request.model';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
})
export class NewRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override portalId = PortalId.AddressNumberRequestPortal;
  mapSettings: MapSettings;
  mapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('districtName', 'GK.MAP.DISTRICT'),
      new ColumnHeader('parcelsNumbers', 'GK.MAP.PARCEL_NUMBER'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.Building],
    true,
    true,
    true,
    true,
    true,
    true,
    false,
  );
  successSigned = false;
  override controlName = BaseNewRequestControlName;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;

  constructor(
    private anrNewRequestFormService: ANRNewRequestFormService,
    private toastr: ToastrService,
    protected override mapStateService: MapStateService,
    protected override mapSettingsService: MapSettingsService,
    protected override docSignService: DocSignService,
    protected override router: Router,
    protected override translateService: TranslateService,
    public override newRequestHelperService: NewRequestHelperService,
  ) {
    super(
      undefined,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
    );
  }

  override ngOnInit(): void {
    this.createForm();
    this.fetchMapSettings();
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
  }

  createForm(): void {
    this.formGroup = this.anrNewRequestFormService.getFormGroup();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.AddressNumberRequest)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
        this.setMapState();
      });
  }

  override setMapState(): void {
    this.mapState = new MapState(
      MapId.AddressNumberRequest,
      this.mapStateService.getViewState(
        MapId.AddressNumberRequest,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.Building, ToolType.LandParcel],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      {
        ...this.initialToolsState,
        [ToolType.Building]: {
          isActive: true,
          buttonText: 'GK.MAP.TOOL_TYPE.BUILDING',
          icon: 'gk-map-icon-select-building',
          label: 'GK.MAP.SELECT_BUILDING',
          mapObjects: [],
          mapGeometryStyleConfig:
            this.initialToolsState[ToolType.Building].mapGeometryStyleConfig,
          [SourceType.Point]: {
            ...this.initialToolsState[ToolType.Building][SourceType.Point],
            isActive: true,
          },
          [SourceType.LineString]:
            this.initialToolsState[ToolType.Building][SourceType.LineString],
          [SourceType.Polygon]:
            this.initialToolsState[ToolType.Building][SourceType.Polygon],
          singleObjectMode: true,
        },
        [ToolType.LandParcel]: auxiliaryLandParcelToolState,
      },
      this.mapStateService.getLayersState(
        MapId.AddressNumberRequest,
        this.mapSettings,
        MapPortalName.AddressNumberRequest,
      ),
      [this.mapObjectTableState],
      undefined,
      true,
      true,
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.isRequestBodyValid()) {
      this.docSignPending = false;

      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }
    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  isRequestBodyValid(): boolean {
    return this.formGroup.valid && this.isBuildingSectionValid();
  }

  isBuildingSectionValid(): boolean {
    return (
      this.isOneMapObjectSelected() &&
      this.isPlaceSelected() &&
      this.isStreetSelectedOrPlaceWithNullStreet()
    );
  }

  isPlaceSelected(): boolean {
    const mapObject = this.mapState.mapObjectTablesState[0].mapObjects[0];

    return !!(mapObject && mapObject.place && mapObject.place.id);
  }

  isStreetSelectedOrPlaceWithNullStreet(): boolean {
    const mapObject = this.mapState.mapObjectTablesState[0].mapObjects[0];

    return !!(
      mapObject &&
      (mapObject.place?.hasNullStreet ||
        (mapObject.street && mapObject.street.id))
    );
  }

  isOneMapObjectSelected(): boolean {
    return (
      this.mapState.mapObjectTablesState[0].mapObjects &&
      this.mapState.mapObjectTablesState[0].mapObjects.length === 1
    );
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    const mapObject = this.mapState.mapObjectTablesState[0]
      .mapObjects[0] as EgibObject;
    this.requestReplyFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            ANRNewRequest.fromAppToApi(
              this.getFormRawValue(),
              mapObject.place.id,
              mapObject.street?.id,
              mapObject.uuid,
              apiDocuments,
            ),
            '/api/interesant/wniosek/ustalenieNumeruPorzadkowego/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: (err) => {
          console.error(err);
          this.handleSendAndValidateFailure();
        },
      });
  }

  getFormRawValue(): ANRNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl('/address-number-request-portal/requests-list');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
