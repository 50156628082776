import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  FieldId,
  LawPerson,
} from '@gk/gk-modules';
import {
  ApplicantRequest,
  ApplicantRequestDto,
  DocumentationFormType,
} from '../../../../shared/base-new-request/base-new-request.model';
import { Shipping } from '../../../../shared/shipping-form/shipping-form.model';

export interface LBRCNNewDataChangesNotificationDto
  extends ApplicantRequestDto {
  ZakresZmiany: string;
  WladanieId: FieldId;
  Lokalizacja: string;
}

export class LBRCNNewDataChangesNotification extends ApplicantRequest {
  constructor(
    lawPerson: LawPerson,
    public changeScope: string,
    documentationForm: DocumentationFormType,
    shippingForm: Shipping,
    public demonstrationOfApplicantRight: FieldId,
    public determinationOfLocation: string,
  ) {
    super(
      lawPerson,
      undefined,
      documentationForm,
      shippingForm,
      undefined,
      undefined,
      undefined,
    );
  }

  static fromAppToApi(
    data: LBRCNNewDataChangesNotification,
    parcels: EgibObject[],
    buildings: EgibObject[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): LBRCNNewDataChangesNotificationDto {
    return {
      ...ApplicantRequest.mapCommonFields({
        data,
        parcels,
        buildings,
        files,
      }),
      ZakresZmiany: data.changeScope,
      WladanieId: data.demonstrationOfApplicantRight,
      Lokalizacja: data.determinationOfLocation,
    };
  }
}
