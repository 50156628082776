<div class="container container-e-service-request">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupAndBirthPlaceDateFormControlsValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.TITLE' | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [legalPersonEnabled]="false"
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>

        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label class="text-nowrap" [attr.for]="controlName.BirthDate">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.FIELDS.BIRTH_DATE'
                  | translate
              }}
            </label>

            <div class="input-group">
              <input
                class="form-control form-control-sm required"
                aria-required="true"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="controlName.BirthDate"
                [name]="controlName.BirthDate"
                [formControlName]="controlName.BirthDate"
                ngbDatepicker
                #d="ngbDatepicker"
              />

              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>

            @if (shouldShowRequiredAlert(this.getBirthDateFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label class="text-nowrap" [attr.for]="controlName.BirthPlace">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.APPLICANT_DETAILS.FIELDS.BIRTH_PLACE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.BirthPlace"
              [id]="controlName.BirthPlace"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.BirthPlace"
            />

            @if (shouldShowRequiredAlert(this.getBirthPlaceFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <app-proxy-details
      #proxyDetails
      [portalId]="portalId"
      [formGroup]="formGroup"
      [controlName]="controlName"
      [submitted]="submitted"
    ></app-proxy-details>

    <section
      class="card mb-4"
      [ngClass]="
        checkWatercraftDataFormControlsValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_DATA'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-4">
            <label [attr.for]="controlName.WatercraftType">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_TYPE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.WatercraftType"
              [id]="controlName.WatercraftType"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.WatercraftType"
            />

            @if (shouldShowRequiredAlert(this.getWatercraftTypeFormControl())) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.StagingPoint">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_STAGING_POINT'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.StagingPoint"
              [id]="controlName.StagingPoint"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.StagingPoint"
            />

            @if (
              shouldShowRequiredAlert(
                this.getWatercraftStagingPointFormControl()
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.DriveType">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.DRIVE_TYPE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.DriveType"
              [id]="controlName.DriveType"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.DriveType"
            />

            @if (
              shouldShowRequiredAlert(this.getWatercraftDriveTypeFormControl())
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkWatercraftDimensionsFormControlsValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_DATA_DIMENSIONS'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-4">
            <label [attr.for]="controlName.TotalLength">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_TOTAL_LENGTH'
                  | translate
              }}
            </label>

            <input
              type="number"
              [name]="controlName.TotalLength"
              [id]="controlName.TotalLength"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.TotalLength"
            />

            @if (
              shouldShowRequiredAlert(
                this.getWatercraftTotalLengthFormControl()
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.SideHeight">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_SIDE_HEIGHT'
                  | translate
              }}
            </label>

            <input
              type="number"
              [name]="controlName.SideHeight"
              [id]="controlName.SideHeight"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.SideHeight"
            />

            @if (
              shouldShowRequiredAlert(this.getWatercraftSideHeightFormControl())
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.LargestWidth">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_LARGEST_WIDTH'
                  | translate
              }}
            </label>

            <input
              type="number"
              [name]="controlName.LargestWidth"
              [id]="controlName.LargestWidth"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.LargestWidth"
            />

            @if (
              shouldShowRequiredAlert(
                this.getWatercraftLargestWidthFormControl()
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.SeatsNumber">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.WATERCRAFT_SEATS_NUMBER'
                  | translate
              }}
            </label>

            <input
              type="number"
              [name]="controlName.SeatsNumber"
              [id]="controlName.SeatsNumber"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.SeatsNumber"
            />

            @if (
              shouldShowRequiredAlert(
                this.getWatercraftSeatsNumberFormControl()
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>

          <div class="form-group col-md-4">
            <label [attr.for]="controlName.ConstructionMaterialType">
              {{
                'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.CONSTRUCTION_MATERIAL_TYPE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.ConstructionMaterialType"
              [id]="controlName.ConstructionMaterialType"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.ConstructionMaterialType"
            />

            @if (
              shouldShowRequiredAlert(
                this.getWatercraftConstructionMaterialTypeFormControl()
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkTechnicalConditionStatementCheckboxFormControlValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.TechnicalConditionStatementCheckbox"
            [formControlName]="controlName.TechnicalConditionStatementCheckbox"
          />

          <label
            class="form-check-label checkbox"
            [attr.for]="controlName.TechnicalConditionStatementCheckbox"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.TECHNICAL_CONDITION_STATEMENT'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkCommitmentStatementCheckboxFormControlValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.CommitmentStatementCheckbox"
            [formControlName]="controlName.CommitmentStatementCheckbox"
          />

          <label
            class="form-check-label checkbox"
            [attr.for]="controlName.CommitmentStatementCheckbox"
          >
            {{
              'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.COMMITMENT_STATEMENT'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.INSTRUCTION'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.INSTRUCTION_INFO'
                | translate
            "
          >
          </P>
        </div>

        <div class="card-footer text-muted">
          <P
            [innerHTML]="
              'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.INSTRUCTION_INFO_FOOTER'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <app-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetails.proxyDetailsVisible"
    >
    </app-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <button type="submit" class="btn btn-primary">
      {{
        'ENVIRONMENTAL_PROTECTION_PORTAL.WATER_EQUIPMENT.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <div class="d-flex flex-column">
      @if (docSignMsgTxt) {
        <span [ngClass]="'text-' + docSignMsgType">
          {{ docSignMsgTxt }}
        </span>
      }

      @if (docSignUrl) {
        <span class="my-2 text-info">
          {{ docSignTranslations['SIGN_INFO'] }}
        </span>
      }

      @if (docSignUrl) {
        <a class="link" [href]="docSignUrl" target="_blank">
          {{ docSignTranslations['SIGN'] }}
        </a>
      }

      @if (docSignPending) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
