import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AnyGeometryObject,
  ApiNewDokumentPowiazanyDalDto,
  ColumnHeader,
  DocSignService,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapAction,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import {
  DateHelperUtil,
  NgbDateRange,
} from '../../../utils/date-helper/date-helper.util';
import {
  LOBWNewRequestControlName,
  LaneOccupancyAreaControlName,
  LaneOccupancyAreaWidth,
} from './services/new-request-form/new-request-form.model';
import { LOBWNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { LOBWNewRequest } from './services/new-request/new-request.model';

@Component({
  selector: 'app-building-works',
  templateUrl: './building-works.component.html',
  styleUrls: ['./building-works.component.scss'],
})
export class BuildingWorksComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = LOBWNewRequestControlName;
  override portalId = PortalId.LaneOccupancyBuildingWorks;
  laneOccupancyAreaControlName = LaneOccupancyAreaControlName;
  laneOccupancyAreaWidth = LaneOccupancyAreaWidth;
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  mapSettings: MapSettings;
  successSigned = false;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private lobwNewRequestFormService: LOBWNewRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.fetchMapSettings();
    this.createForm();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.mapSettings = mapSettings;
      });
  }

  createForm(): void {
    this.formGroup = this.lobwNewRequestFormService.getFormGroup();
  }

  isLocationFormGroupValid(): boolean {
    return (
      this.isRoadNumberFormControlValid() && this.isPlaceFormControlValid()
    );
  }

  isRoadNumberFormControlValid(): boolean {
    return this.getRoadNumberFormControl().valid;
  }

  getRoadNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.RoadNumber,
    ) as UntypedFormControl;
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  isLaneOccupancyPurposeFormControlValid(): boolean {
    return this.getLaneOccupancyPurposeFormControl().valid;
  }

  getLaneOccupancyPurposeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.LaneOccupancyPurpose,
    ) as UntypedFormControl;
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.isEveryLaneOccupancyIssueRangeChosen() &&
      this.areSomeLaneOccupancyAreaFormGroups()
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            LOBWNewRequest.fromAppToApi(this.getFormValue(), apiDocuments),
            '/api/interesant/wniosek/pasZajecieRobotyBudowlane/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getFormValue(): LOBWNewRequest {
    return this.formGroup.getRawValue();
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl('/lane-occupancy-building-works/requests-list');
  }
  isDateRangeInvalid(index: number): boolean {
    return !this.isDateRangeCorrect(index) || this.isDateRangeEmpty(index);
  }

  isDateRangeCorrect(index: number): boolean {
    const formControlValue =
      this.getLaneOccupancyDateFormControl(index)?.value || {};

    return DateHelperUtil.isToDateNotLessThanFromDate(
      formControlValue.from,
      formControlValue.to,
    );
  }

  isDateRangeEmpty(index: number): boolean {
    const formControlValue =
      this.getLaneOccupancyDateFormControl(index).value || {};

    return _.isEmpty(formControlValue.from) && _.isEmpty(formControlValue.to);
  }

  updateDateRangeFormControl(dateRange: NgbDateRange, index: number): void {
    const formControl = this.getLaneOccupancyDateFormControl(index);
    if (!formControl) {
      return;
    }
    formControl.setValue(dateRange);
  }

  getLaneOccupancyDateFormControl(index: number): UntypedFormControl {
    const formControl = this.getLaneOccupancyFormGroup(index);
    return (
      formControl &&
      (formControl.get(
        LaneOccupancyAreaControlName.DateRange,
      ) as UntypedFormControl)
    );
  }

  addLaneOccupancyAreaFormControl(): void {
    this.getLaneOccupancyAreaFormArray().push(
      this.lobwNewRequestFormService.getLaneOccupancyAreaFormGroup(
        this.createLaneOccupancyAreaMapState(),
      ),
    );
  }

  deleteLaneOccupancyAreaFormControl(index: number): void {
    this.getLaneOccupancyAreaFormArray().removeAt(index);
  }

  createLaneOccupancyAreaMapState(): MapState {
    return new MapState(
      MapId.LaneOccupancyPortalBuildingWorksLocalisation,
      this.mapStateService.getViewState(
        MapId.LaneOccupancyPortalBuildingWorksLocalisation,
        this.mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon, ToolType.LandParcel],
        this.mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.LaneOccupancyPortalBuildingWorksLocalisation,
        this.mapSettings,
        MapPortalName.Applicant,
      ),
      this.createLaneOccupancyAreaMapObjectTableState(),
    );
  }

  createLaneOccupancyAreaMapObjectTableState(): MapObjectTableState[] {
    return [
      new MapObjectTableState(
        [
          new ColumnHeader(
            'mapObjectNumber',
            'PARCEL_SEARCH_FORM.PARCEL_NUMBER',
          ),
          new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
          new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
          new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
          new ColumnHeader(
            'areaInSquareMeters',
            'GK.MAP.AREA_IN_SQUARE_METERS',
          ),
        ],
        undefined,
        this.defaultMapGeometryStyles,
        true,
        true,
        true,
        '25',
      ),
    ];
  }

  isLaneOccupancyAreaFormGroupValid(index: number): boolean {
    return (
      this.isLaneOccupancyFormGroupValid(index) &&
      this.isSomeLaneOccupancyIssueRangeChosen(index)
    );
  }

  isLaneOccupancyFormGroupValid(index: number): boolean {
    const formGroup = this.getLaneOccupancyFormGroup(index);

    return formGroup && formGroup.valid;
  }

  getRoadWidthFormControl(index: number): UntypedFormControl {
    const formGroup = this.getLaneOccupancyFormGroup(index);

    return (
      formGroup &&
      (formGroup.get(
        LaneOccupancyAreaControlName.RoadWidth,
      ) as UntypedFormControl)
    );
  }

  getLaneOccupancyAreaFormControl(index: number): UntypedFormControl {
    const formGroup = this.getLaneOccupancyFormGroup(index);

    return (
      formGroup &&
      (formGroup.get(LaneOccupancyAreaControlName.Road) as UntypedFormControl)
    );
  }

  getLaneOccupancyRoadsideComponentsAreaFormControl(
    index: number,
  ): UntypedFormControl {
    const formGroup = this.getLaneOccupancyFormGroup(index);

    return (
      formGroup &&
      (formGroup.get(
        LaneOccupancyAreaControlName.RoadsideComponents,
      ) as UntypedFormControl)
    );
  }

  getLaneOccupancyRoadLaneOtherElementsAreaFormControl(
    index: number,
  ): UntypedFormControl {
    const formGroup = this.getLaneOccupancyFormGroup(index);

    return (
      formGroup &&
      (formGroup.get(
        LaneOccupancyAreaControlName.RoadLaneOtherElements,
      ) as UntypedFormControl)
    );
  }

  getLaneOccupancyFormGroup(index: number): UntypedFormGroup {
    return this.getLaneOccupancyAreaFormArray().controls[
      index
    ] as UntypedFormGroup;
  }

  isEveryLaneOccupancyIssueRangeChosen(): boolean {
    return this.getLaneOccupancyAreaFormArray().controls.every((control) =>
      this.isControlHasRangeSelected(control.value.mapState),
    );
  }

  isControlHasRangeSelected(mapState: MapState): boolean {
    return mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
    );
  }

  isSomeLaneOccupancyIssueRangeChosen(index: number): boolean {
    return this.getLaneOccupancyMapObjectsTableState(index).mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
    );
  }

  getLaneOccupancyMapObjectsTableState(index: number): MapObjectTableState {
    return this.getLaneOccupancyAreaMapStateFormControl(index).value
      .mapObjectTablesState[0];
  }

  getLaneOccupancyAreaFormArray(): UntypedFormArray {
    return this.formGroup.get(
      LOBWNewRequestControlName.LaneOccupancyArea,
    ) as UntypedFormArray;
  }

  handleLaneOccupancyAreaMapAction(mapAction: MapAction, index: number): void {
    const formControl = this.getLaneOccupancyAreaMapStateFormControl(index);
    formControl.setValue(
      this.mapStateService.getUpdatedMapState(mapAction, formControl.value),
    );
    this.setAreaFromMapObjectsArea(index);
  }

  setAreaFromMapObjectsArea(index: number): void {
    const sumArea = (
      this.getLaneOccupancyAreaMapStateFormControl(index).value
        .mapObjectTablesState[0].mapObjects as AnyGeometryObject[]
    )
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .reduce((a, b) => a + b.areaInSquareMeters, 0);
    this.getLaneOccupancyAreaCalculatedAreaFormControl(index).setValue(sumArea);
  }

  getLaneOccupancyAreaMapStateFormControl(index: number): UntypedFormControl {
    return this.getLaneOccupancyAreaFormArray().controls[index].get(
      LaneOccupancyAreaControlName.MapState,
    ) as UntypedFormControl;
  }

  getLaneOccupancyAreaCalculatedAreaFormControl(
    index: number,
  ): UntypedFormControl {
    return this.getLaneOccupancyAreaFormArray().controls[index].get(
      LaneOccupancyAreaControlName.CalculatedArea,
    ) as UntypedFormControl;
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty ||
      this.attachmentsComponent.areChosenDocuments() ||
      this.areSomeLaneOccupancyAreaFormGroups()
    );
  }

  areSomeLaneOccupancyAreaFormGroups(): boolean {
    return !!this.getLaneOccupancyAreaFormArray().controls.length;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
