import { Injectable } from '@angular/core';
import {
  ButtonStateControl,
  ControlConfig,
  GeneralConfig,
  ListConfigs,
} from '@gk/gk-modules';
import { map, Observable } from 'rxjs';
import {
  dtoAvailableParcelsConfig,
  ecoPortalDocumentsConfig,
  getDtoDesignerConfig,
  getDtoEServicesConfig,
  getDtoPrzpConfig,
  getSurveyorPortalAcceptedWorksListConfig,
  surveyorPortalPendingWorksListConfig,
} from '../../dynamic-list-configuration';
import { DesignerSettingsService } from '../designer-settings/designer-settings.service';

@Injectable()
export class ConfigManagerService {
  private ecoPortalDocumentsConfig = { ...ecoPortalDocumentsConfig };
  private surveyorPortalPendingWorksListConfig = {
    ...surveyorPortalPendingWorksListConfig,
  };
  private dtoAvailableParcelsConfig = {
    ...dtoAvailableParcelsConfig,
  };

  constructor(private designerSettingsService: DesignerSettingsService) {}

  getConfigForDesignerDtoList(
    portalId?: number,
    openCommunicatorCallback?: (
      dynamicListItem: any,
      buttonStateControl: ButtonStateControl,
    ) => void,
    readNewMessageCallback?: (
      dynamicListItem: any,
      refreshCallback: () => any,
    ) => void,
  ): Observable<ListConfigs> {
    return this.designerSettingsService.isPzSessionOrPzSignRequired().pipe(
      map((isPzSessionOrPzSignRequired) => ({
        ...getDtoDesignerConfig(
          portalId,
          isPzSessionOrPzSignRequired,
          openCommunicatorCallback,
          readNewMessageCallback,
        ),
      })),
    );
  }

  getConfigForEcoPortalDocumentsList(): ListConfigs {
    return this.ecoPortalDocumentsConfig;
  }

  getConfigForEcoPortalDocumentDetails(): ControlConfig[] {
    return this.ecoPortalDocumentsConfig.listDataConfig.controlConfigs;
  }

  getConfigForEServicesRequestsList(
    portalId?: number,
    openCommunicatorCallback?: (
      dynamicListItem: any,
      buttonStateControl: ButtonStateControl,
    ) => void,
    readNewMessageCallback?: (
      dynamicListItem: any,
      refreshCallback: () => any,
    ) => void,
  ): ListConfigs {
    return { ...getDtoEServicesConfig(portalId, openCommunicatorCallback, readNewMessageCallback) };
  }

  getConfigForSurveyorPortalAcceptedWorksList(
    supplementaryDeclarationCallback: (
      dynamicListItem: any,
      buttonStateControl: ButtonStateControl,
    ) => void,
    openCommunicatorCallback?: (
      dynamicListItem: any,
      buttonStateControl: ButtonStateControl,
    ) => void,
    readNewMessageCallback?: (
      dynamicListItem: any,
      refreshCallback: () => any,
    ) => void,
  ): ListConfigs {
    return getSurveyorPortalAcceptedWorksListConfig(
      supplementaryDeclarationCallback,
      openCommunicatorCallback,
      readNewMessageCallback,
    );
  }

  getConfigForSurveyorPortalPendingWorksList(): ListConfigs {
    return this.surveyorPortalPendingWorksListConfig;
  }

  getConfigForAvailableParcelsList(): ListConfigs {
    return this.dtoAvailableParcelsConfig;
  }

  getConfigForPrzp(): ListConfigs {
    return { ...getDtoPrzpConfig() };
  }

  setOpenFirstPanelOnInitFlag(
    generalConfig: GeneralConfig,
    value: boolean,
  ): void {
    generalConfig.shouldOpenFirstPanelOnInit = value;
  }
}
