import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CommunicatorListItem,
  ListConfigs,
  ListState,
  MapAction,
  MapSettingsService,
} from '@gk/gk-modules';
import { takeWhile } from 'rxjs/operators';
import { AppTitleService } from '../../services/app-title/app-title.service';
import { ConfigManagerService } from '../../services/config-manager/config-manager.service';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import { BaseRequestsListDirective } from '../base-requests-list/base-requests-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './main-requests-list.component.html',
})
export class MainRequestsListComponent
  extends BaseRequestsListDirective
  implements OnInit, OnDestroy
{
  override listConfigs: ListConfigs;
  listState = {} as ListState;
  pendingMapActions: MapAction[] = [];

  constructor(
    private configManagerService: ConfigManagerService,
    private mapSettingsService: MapSettingsService,
    public override appTitleService: AppTitleService,
    public override webPortalService: WebPortalService,
    public override modalService: NgbModal,
  ) {
    super(appTitleService, webPortalService, modalService);
  }

  ngOnInit(): void {
    this.setListConfigs();
  }

  setListConfigs(): void {
    this.getCurrentPortalType()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((webPortal) => {
        this.listConfigs =
          this.configManagerService.getConfigForEServicesRequestsList(
            webPortal.portalId,
            (dynamicListItem, buttonStateControl) =>
              this.handleOpenCommunicator(
                CommunicatorListItem.fromApiEServicesToApp(dynamicListItem),
                buttonStateControl,
              ),
            (dynamicListItem, refreshCallback) =>
              this.handleReadNewMessage(dynamicListItem, refreshCallback),
          );
      });
  }

  handleMapAction(mapAction: MapAction): void {
    this.pendingMapActions = [...this.pendingMapActions, mapAction];
  }

  dispatchPendingMapActions(): void {
    this.mapSettingsService.pendingMapActions.next(this.pendingMapActions);
  }

  override ngOnDestroy(): void {
    this.isAlive = false;
    this.dispatchPendingMapActions();
  }
}
