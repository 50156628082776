import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AnyGeometryObject,
  ApiNewDokumentPowiazanyDalDto,
  ColumnHeader,
  DocSignService,
  FormAlertService,
  LawPersonSimpleFormComponent,
  MapAction,
  MapId,
  MapObjectApiType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  PortalId,
  PzService,
  StreetService,
  ToolType,
  markFormGroupsTouched,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { RequestInProgress } from '../../../guards/request-in-progress/request-in-progress.guard';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import { PlaceService } from '../../../services/place/place.service';
import { BsMessageType } from '../../../services/request-workspace-state/request-workspace-state.model';
import { AttachmentsComponent } from '../../../shared/attachments/attachments.component';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { RequestReplyFormComponent } from '../../../shared/request-reply-form/request-reply-form.component';
import {
  DateHelperUtil,
  NgbDateRange,
} from '../../../utils/date-helper/date-helper.util';
import { LOIDNewRequestControlName } from './services/new-request-form/new-request-form.model';
import { LOIDNewRequestFormService } from './services/new-request-form/new-request-form.service';
import { LOIDNewRequest } from './services/new-request/new-request.model';

@Component({
  selector: 'app-infrastructure-devices',
  templateUrl: './infrastructure-devices.component.html',
  styleUrls: ['./infrastructure-devices.component.scss'],
})
export class InfrastructureDevicesComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy, RequestInProgress
{
  override controlName = LOIDNewRequestControlName;
  override portalId = PortalId.LaneOccupancyInfrastructureDevices;
  laneLocationMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
      new ColumnHeader('typeName', 'GK.MAP.TYPE', true),
      new ColumnHeader('areaInSquareMeters', 'GK.MAP.AREA_IN_SQUARE_METERS'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    true,
    true,
    '25',
  );
  @ViewChild(LawPersonSimpleFormComponent)
  lawPersonSimpleFormComponent: LawPersonSimpleFormComponent;
  calculatedArea: number;
  @ViewChild('canDeactivateModal') canDeactivateModal: NgbModalRef;
  @ViewChild(RequestReplyFormComponent)
  requestReplyFormComponent: RequestReplyFormComponent;
  @ViewChild(AttachmentsComponent) attachmentsComponent: AttachmentsComponent;
  successSigned = false;

  constructor(
    public override mapStateService: MapStateService,
    public override mapSettingsService: MapSettingsService,
    public override formAlertService: FormAlertService,
    public override placeService: PlaceService,
    public override translateService: TranslateService,
    public override docSignService: DocSignService,
    public override newRequestHelperService: NewRequestHelperService,
    private loidNewRequestFormService: LOIDNewRequestFormService,
    public override streetService: StreetService,
    private toastr: ToastrService,
    public override router: Router,
    private modalService: NgbModal,
    public override pzService: PzService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
      formAlertService,
      placeService,
      streetService,
    );
  }

  override ngOnInit(): void {
    this.fetchWrongFileText();
    this.subscribeToDocSignTranslations();
    this.fetchMapSettings();
    this.createForm();
  }

  fetchMapSettings(): void {
    this.mapSettingsService
      .getMapSettings(MapPortalName.Applicant)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((mapSettings) => {
        this.setLaneLocationMapState(mapSettings);
      });
  }

  createForm(): void {
    this.formGroup = this.loidNewRequestFormService.getFormGroup();
  }

  setLaneLocationMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.LaneOccupancyPortalInfrastructureDevicesLocalisation,
      this.mapStateService.getViewState(
        MapId.LaneOccupancyPortalInfrastructureDevicesLocalisation,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.AnyPolygon, ToolType.LandParcel],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.LaneOccupancyPortalInfrastructureDevicesLocalisation,
        mapSettings,
        MapPortalName.Applicant,
      ),
      [this.laneLocationMapObjectTableState],
    );
  }

  isLocationFormGroupValid(): boolean {
    return (
      this.isRoadNumberFormControlValid() && this.isPlaceFormControlValid()
    );
  }

  isAreaFormGroupValid(): boolean {
    return (
      this.areLaneLocationIssueRangesChosen() && this.areAreaControlsValid()
    );
  }

  isRoadNumberFormControlValid(): boolean {
    return this.getRoadNumberFormControl().valid;
  }

  getRoadNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.RoadNumber,
    ) as UntypedFormControl;
  }

  isPlaceFormControlValid(): boolean {
    return this.getPlaceFormControl().valid;
  }

  getAreaWithoutRoadEngineeringFacilitiesFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.AreaWithoutRoadEngineeringFacilities,
    ) as UntypedFormControl;
  }

  getAreaOfRoadEngineeringFacilitiesFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.AreaOfRoadEngineeringFacilities,
    ) as UntypedFormControl;
  }

  isLaneOccupancyPurposeFormControlValid(): boolean {
    return this.getLaneOccupancyPurposeFormControl().valid;
  }

  getLaneOccupancyPurposeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.LaneOccupancyPurpose,
    ) as UntypedFormControl;
  }

  isRequestValid(): boolean {
    return (
      this.formGroup.valid &&
      this.attachmentsComponent.areDocumentsValid() &&
      this.areLaneLocationIssueRangesChosen() &&
      !this.isDateRangeInvalid() &&
      this.areAreaControlsValid()
    );
  }

  areLaneLocationIssueRangesChosen(): boolean {
    return this.mapState.mapObjectTablesState[0].mapObjects.some(
      (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
    );
  }

  areAreaControlsValid(): boolean {
    const areaWithoutRoadEngineeringFacilities =
      this.getAreaWithoutRoadEngineeringFacilitiesFormControl();
    const areaOfRoadEngineeringFacilities =
      this.getAreaOfRoadEngineeringFacilitiesFormControl();

    return (
      areaOfRoadEngineeringFacilities.value ||
      areaWithoutRoadEngineeringFacilities.value
    );
  }

  handleLaneLocationMapAction(mapAction: MapAction): void {
    this.mapState = this.mapStateService.getUpdatedMapState(
      mapAction,
      this.mapState,
    );
    this.setAreaFromMapObjectsArea();
  }

  setAreaFromMapObjectsArea(): void {
    const sumArea = (
      this.mapState.mapObjectTablesState[0].mapObjects as AnyGeometryObject[]
    )
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .reduce((a, b) => a + b.areaInSquareMeters, 0);
    this.calculatedArea = sumArea;
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    markFormGroupsTouched(this.getLawPersonFormGroup());
    if (!this.isRequestValid()) {
      return;
    }
    const apiFiles = await Promise.all(
      this.attachmentsComponent.getConvertedFiles(),
    ).catch(() => new Error());
    if (
      (Array.isArray(apiFiles) &&
        apiFiles.some((file) => file instanceof Error)) ||
      apiFiles instanceof Error
    ) {
      this.errorSubmitMessage = this.wrongFilesErrorText;
      this.toastr.error(this.wrongFilesErrorText);

      return;
    }

    this.sendRequest(apiFiles as ApiNewDokumentPowiazanyDalDto[]);
  }

  sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.lawPersonSimpleFormComponent
      .askForPostOfficeWhenPostalCodeIsNotFromDictionary()
      .pipe(
        mergeMap(() =>
          this.requestReplyFormComponent.askForPostOfficeWhenPostalCodeIsNotFromDictionary(),
        ),
        tap(() => {
          this.docSignPending = true;
          this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
        }),
        switchMap(() =>
          this.docSignService.addToSign(
            LOIDNewRequest.fromAppToApi(
              this.getFormValue(),
              this.getIssueRanges(),
              apiDocuments,
            ),
            '/api/interesant/wniosek/pasZajeciePrzezUrzadzenie/addToSign',
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleSendAndValidateSuccess(addedDocToSignResponse);
        },
        error: () => this.handleSendAndValidateFailure(),
      });
  }

  getFormValue(): LOIDNewRequest {
    return this.formGroup.getRawValue();
  }

  getIssueRanges(): string[] {
    return [...(this.mapState.mapObjectTablesState[0].mapObjects || [])]
      .filter(
        (mapObject) => mapObject.type === MapObjectApiType.ExtentOrPolygon,
      )
      .map((issueRange) => issueRange.geom);
  }

  override handleDocSignSuccess(): void {
    this.successSigned = true;
    this.docSignUrl = '';
    this.router.navigateByUrl(
      '/lane-occupancy-infrastructure-devices/requests-list',
    );
  }

  isDateRangeInvalid(): boolean {
    return !this.isDateRangeCorrect() || this.isDateRangeEmpty();
  }

  isDateRangeCorrect(): boolean {
    const formControlValue = this.getLaneOccupancyDateFormControl().value || {};

    return DateHelperUtil.isToDateNotLessThanFromDate(
      formControlValue.from,
      formControlValue.to,
    );
  }

  isDateRangeEmpty(): boolean {
    const formControlValue = this.getLaneOccupancyDateFormControl().value || {};

    return _.isEmpty(formControlValue.from) && _.isEmpty(formControlValue.to);
  }

  updateDateRangeFormControl(dateRange: NgbDateRange): void {
    this.getLaneOccupancyDateFormControl().setValue(dateRange);
  }

  getLaneOccupancyDateFormControl(): UntypedFormControl {
    return this.formGroup.get(
      LOIDNewRequestControlName.LaneOccupancyDate,
    ) as UntypedFormControl;
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.isRequestDirty() && !this.successSigned
      ? from(
          this.modalService.open(this.canDeactivateModal, {
            beforeDismiss: () => false,
          }).result,
        )
      : true;
  }

  isRequestDirty(): boolean {
    return (
      this.formGroup.dirty ||
      this.attachmentsComponent.areChosenDocuments() ||
      this.areLaneLocationIssueRangesChosen() ||
      !this.isDateRangeEmpty()
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
