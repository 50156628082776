export enum MapPortalName {
  Przp = 'przp',
  Shop = 'skleponline',
  FreeServices = 'przp',
  Designer = 'projektant',
  Applicant = 'interesant',
  AddressNumberRequest = 'interesant',
  Surveyor = 'geodeta',
  Planning = 'planowanie',
  ReportEgibProject = 'operategibprojekt',
  Appraiser = 'appraiser',
  CoordinationMeeting = 'coordination-meeting',
}

export enum MapId {
  NewDesignerRequestParcelsAndIssueRange = 'map-new-designer-request-parcels-and-issue-range',
  NewAgriculturalLandUseRequestParcelsAndExclusionArea = 'map-new-agricultural-land-use-request-parcels-and-exclusion-range',
  NewEnvironmentalProtectionPortalRequest = 'new_environmental_protection_portal_request',
  NewNonAgriculturalLandUseRequest = 'map-new-non-agricultural-land-use-request',
  Designer = 'map-designer',
  Surveyor = 'map-surveyor',
  Appraiser = 'map-appraiser',
  NewFreeServicesRequestParcels = 'map-new-free-services-rquest-parcels',
  NewFreeServicesRequestPolygon = 'map-new-free-services-request-polygon',
  FreeServices = 'map-free-services',
  EcoPortalDetails = 'map-eco-portal-details',
  RoadExitPortalBuildPermissionRequest = 'map-road-exit-portal-build-permission-request',
  LaneOccupancyPortalInfrastructureDevicesLocalisation = 'lane-occupancy-portal-infrastructure-devices-localisation',
  LaneOccupancyPortalBuildingObjectsLocalisation = 'lane-occupancy-portal-building-objects-localisation',
  LaneOccupancyPortalBuildingObjectsAreaWithoutRoadEngineeringFacilities = 'lane-occupancy-portal-building-objects-area-without-road-engineering-facilities',
  LandAndBuildingRegisterDataChangesNotificationRequest = 'land-and-building-register-data-changes-notification',
  SoilClassificationOfLandNewCarryOutRequest = 'soil-classification-of-land-new-carry-out-request',
  PresentationCommentsLandBuildingRegistryProject = 'presentation-comments-land-building-registry-project',
  LaneOccupancyPortalBuildingWorksLocalisation = 'lane-occupancy-portal-building-works-localisation',
  NewConstructionPortalRequest = 'new-construction-portal-request-request',
  NewDemolitionPortalRequest = 'new-demolition-portal-request-request',
  BuildingPermitTransfer = 'new-building-permit-transfer-request',
  PlanningDepartmentExtractFromLocalSpatialDevelopmentPlan = 'planning-department-extract-from-local-spatial-development-plan',
  AddressNumberRequest = 'address-number-request',
  SurveyorNewRequest = 'surveyor-new-request',
  SurveyorSupplementaryDeclaration = 'surveyor-supplementary-declaration',
  PropertyLease = 'property-lease',
  SupplementaryParcelPurchase = 'supplementary-parcel-purchase',
  OwnershipConversion = 'ownership-conversion',
  StreetNaming = 'street-naming',
  CommunalPremisePurchase = 'communal-premise-purchase',
  ApplicantSpecification = 'map-applicant-specification',
  AccessToInformationOnEnvironmentAndItsProtection = 'access-to-information-on-environment-and-its-protection',
  BuildingPermit = 'building-permit',
  DemolitionPermit = 'demolition-permit',
  BuildingOrPartUseChange = 'building-or-part-use-change',
  TemporaryStructureBuildingPermit = 'temporary-structure-building-permit',
  BuildingPermitModificationDecision = 'building-permit-modification-decision',
  PlotLandDevArchConstrProjSeparateDecision = 'plot-land-dev-arch-constr-proj-separate-decision',
  AdjacentPropertyNeedEnterDecision = 'adjacent-property-need-enter-decision',
  SingleFamilyBuilding70ConstructionConversion = 'single-family-building-70-construction-conversion',
  SingleFamilyBuildingConstructionConversion = 'single-family-building-construction-conversion',
  GeodeticAndCartographicServiceDataRequest = 'geodetic-and-cartographic-service-data-request',
  CoordinationMeetingPortal = 'map-coordination-meeting-portal',
  LandAndBuildingRegisterDataUpdateRequest = 'land-and-building-register-data-update-request',
  LandAndBuildingRegisterDataUpdateUponEntitiesRequest = 'land-and-building-register-data-update-upon-entities-request',
}
