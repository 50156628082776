<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <app-request-title></app-request-title>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isLawPersonFormGroupValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.CLIENT_DATA'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-law-person-simple-form
            [lawPersonFormGroup]="getLawPersonFormGroup()"
          >
          </gk-law-person-simple-form>
        </div>
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          submitted && !areMapObjectsChosen()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.LOCATION'
              | translate
          }}
        </div>

        <div class="card-body">
          <gk-map
            [mapState]="mapState"
            (dispatch)="handleMapAction($event)"
          ></gk-map>

          <div class="my-2">
            @if (mapState.mapObjectTablesState[0].mapObjects.length) {
              <gk-map-object-table
                [mapState]="mapState"
                [mapObjectTableStateIndex]="0"
                (dispatch)="handleMapAction($event)"
              ></gk-map-object-table>
            }
          </div>
        </div>
        @if (submitted && !areMapObjectsChosen()) {
          <div class="card-footer">
            <div class="alert alert-danger mt-2">
              {{
                'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.MAP_OBJECTS_REQUIRED'
                  | translate
              }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isDemonstrationOfApplicantRightFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label
            class="mb-0"
            [attr.for]="controlName.DemonstrationOfApplicantRight"
          >
            {{
              'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.DEMONSTRATION_OF_APPLICANT_RIGHT'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <div class="d-flex row-select">
            <select
              [attr.id]="controlName.DemonstrationOfApplicantRight"
              [title]="'NEW_DESIGNER_REQUEST_FORM.PERSON_TYPE' | translate"
              [name]="controlName.DemonstrationOfApplicantRight"
              [formControlName]="controlName.DemonstrationOfApplicantRight"
              class="form-select form-select-sm required"
              aria-required="true"
            >
              @for (
                field of demonstrationOfApplicantRightService.getSimplifiedDictionary()
                  | async;
                track field.id
              ) {
                <option [ngValue]="field.id">
                  {{ field.name }}
                </option>
              }
            </select>
          </div>
        </div>

        @if (
          shouldShowRequiredAlert(getDemonstrationOfApplicantRightFormControl())
        ) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isReasonFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.Reason">
            {{
              'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.REASON'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <textarea
            [name]="controlName.Reason"
            [id]="controlName.Reason"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.Reason"
          ></textarea>
        </div>

        @if (shouldShowRequiredAlert(getReasonFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <section>
      <div
        class="card mb-4"
        [ngClass]="
          !isEnableWorkByPersonFormControlValid() && submitted
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          <label class="mb-0" [attr.for]="controlName.EnableWorkByPerson">
            {{
              'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.ENABLE_WORK_BY_PERSON'
                | translate
            }}
          </label>
        </div>

        <div class="card-body">
          <input
            type="text"
            [name]="controlName.EnableWorkByPerson"
            [id]="controlName.EnableWorkByPerson"
            class="form-control required"
            aria-required="true"
            [formControlName]="controlName.EnableWorkByPerson"
          />
        </div>

        @if (shouldShowRequiredAlert(getEnableWorkByPersonFormControl())) {
          <div class="card-footer">
            <div class="alert alert-danger">
              {{ 'FIELD_REQUIRED' | translate }}
            </div>
          </div>
        }
      </div>
    </section>

    <app-attachments [portalId]="portalId" [submitted]="submitted">
    </app-attachments>

    <app-request-reply-form
      [portalId]="portalId"
      [formGroup]="formGroup"
      [submitted]="submitted"
    ></app-request-reply-form>

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <section class="my-2">
      @if (submitted && !isRequestValid()) {
        <div class="alert alert-danger mt-1">
          <span>
            {{
              'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.PROVIDE_REQUIRED_FIELDS'
                | translate
            }}
          </span>
        </div>
      }

      @if (errorSubmitMessage) {
        <div class="alert alert-danger mt-2">
          <span>{{ errorSubmitMessage }}</span>
        </div>
      }
    </section>

    <section>
      <button type="submit" class="btn btn-primary">
        {{
          'SOIL_CLASSIFICATION_OF_LAND.NEW_CARRY_OUT_REQUEST.SUBMIT' | translate
        }}
      </button>

      <app-request-cancel-button></app-request-cancel-button>

      <div class="d-flex flex-column">
        @if (docSignMsgTxt) {
          <span [ngClass]="'text-' + docSignMsgType">
            {{ docSignMsgTxt }}
          </span>
        }

        @if (docSignUrl) {
          <span class="my-2 text-info">
            {{ docSignTranslations['SIGN_INFO'] }}
          </span>
        }

        @if (docSignUrl) {
          <a class="link" [href]="docSignUrl" target="_blank">
            {{ docSignTranslations['SIGN'] }}
          </a>
        }

        @if (docSignPending) {
          <div class="d-flex justify-content-center my-3">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
            </div>
          </div>
        }
      </div>

      <div class="mt-2"><app-form-legend></app-form-legend></div>
    </section>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
