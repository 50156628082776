import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AddedDocToSignResponse,
  ApiNewDokumentPowiazanyDalDto,
  Applicant,
  ColumnHeader,
  DictionaryField,
  DocSignService,
  EgibObject,
  FileExtension,
  LawPersonSearch,
  LawPersonService,
  LegalPersonControlName,
  MapAction,
  MapId,
  MapObject,
  MapObjectApiType,
  MapObjectTableActionType,
  MapObjectTableState,
  MapPortalName,
  MapSettings,
  MapSettingsService,
  MapState,
  MapStateService,
  NaturalPersonSearch,
  NewDesignerRequestDto,
  NewNaturalPersonOrDesignerRequestDto,
  PersonType,
  PersonTypeService,
  PetentData,
  PzService,
  ToolType,
  requiredPersonTypes,
} from '@gk/gk-modules';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import {
  Observable,
  catchError,
  combineLatestWith,
  filter,
  forkJoin,
  from,
  map,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { AddressListMode } from '../../../services/address/address.model';
import { ClosedArea } from '../../../services/closed-areas/closed-areas.model';
import { ClosedAreasService } from '../../../services/closed-areas/closed-areas.service';
import {
  ConnectionAndNetwork,
  ConnectionAndNetworkFormGroupContent,
} from '../../../services/connection-and-network/connection-and-network.model';
import { ConnectionAndNetworkService } from '../../../services/connection-and-network/connection-and-network.service';
import { DesignerIncomingDocumentsService } from '../../../services/designer-incoming-documents/designer-incoming-documents.service';
import { DesignerSettings } from '../../../services/designer-settings/designer-settings.model';
import { DesignerSettingsService } from '../../../services/designer-settings/designer-settings.service';
import { DocTypeService } from '../../../services/doc-type/doc-type.service';
import { PortalType } from '../../../services/file-type-matcher/file-type-matcher.model';
import { FormsStateService } from '../../../services/forms-state/forms-state.service';
import { NewDesignerRequestControlName } from '../../../services/new-designer-request-form/new-designer-request-form.model';
import {
  ApiNewZudSprawaWniosekDalDto,
  AttachmentFileInputId,
  DocFile,
  MaxLengthDesignerRequest,
  NewDesignerRequestFormModel,
  ParcelTabId,
} from '../../../services/new-designer-request/new-designer-request.model';
import { NewDesignerRequestService } from '../../../services/new-designer-request/new-designer-request.service';
import { NewRequestHelperService } from '../../../services/new-request-helper/new-request-helper.service';
import {
  BsMessageType,
  DesignerNewRequestFormWorkspaceState,
  WorkspaceStateDraftVersion,
  WorkspaceStateId,
} from '../../../services/request-workspace-state/request-workspace-state.model';
import { SessionService } from '../../../services/session/session.service';
import { SettingsService } from '../../../services/settings/settings.service';
import { BaseNewRequestComponent } from '../../../shared/base-new-request/base-new-request.component';
import { PermissionNumberModalComponent } from '../../../shared/permission-number-modal/permission-number-modal.component';
import { InfoCrossingClosedAreasModalComponent } from '../info-crossing-closed-areas-modal/info-crossing-closed-areas-modal.component';
import { DocsTableEditableComponent } from './docs-table-editable/docs-table-editable.component';

@Component({
  selector: 'app-new-designer-request',
  templateUrl: './new-designer-request.component.html',
  styleUrls: ['./new-designer-request.component.scss'],
})
export class NewDesignerRequestComponent
  extends BaseNewRequestComponent
  implements OnInit, OnDestroy
{
  override controlName = NewDesignerRequestControlName;
  connectionsAndNetworksOptions: DictionaryField[] = [];
  addressListMode = AddressListMode.Removing;
  showAddressSearchForm = false;
  successfullyAdded = false;
  validated = false;
  disabledSubmitButton = false;
  showTypedAddress = false;
  attachmentFileInputIdEnum = AttachmentFileInputId;
  fileExtensionEnum = FileExtension;
  parcelTabIdEnum = ParcelTabId;
  connectionsAndNetworkError = '';
  connectionsAndNetworksTranslations: { [key: string]: string } = {};
  @ViewChild(DocsTableEditableComponent)
  docsTableEditableComponent: DocsTableEditableComponent;
  @ViewChild(PermissionNumberModalComponent)
  permissionNumberModalComponent: PermissionNumberModalComponent;
  parcelsMapObjectTableState = new MapObjectTableState(
    [
      new ColumnHeader('mapObjectNumber', 'PARCEL_SEARCH_FORM.PARCEL_NUMBER'),
      new ColumnHeader('districtName', 'PARCEL_SEARCH_FORM.DISTRICT'),
      new ColumnHeader('mapSheet', 'PARCEL_SEARCH_FORM.SHEET'),
    ],
    undefined,
    this.defaultMapGeometryStyles,
    true,
    false,
    true,
    '25',
    this.formsStateService.newDesignerRequestChosenParcels,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [MapObjectApiType.LandParcel],
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    false,
  );
  issueRangesMapObjectTableState: MapObjectTableState;
  workspaceStateDraftVersion =
    WorkspaceStateDraftVersion.DesignerNewRequestFormVersion;
  workspaceStateId = WorkspaceStateId.DesignerNewRequestForm;
  triedToAddConnectionAndNetwork = false;
  docFiles: DocFile[] = [];
  portalTypeEnum = PortalType;
  closedAreas: ClosedArea[] = [];
  closedAreasInvalid = false;
  loadingClosedAreas = this.closedAreasService.waitingForResponse;
  chosenLawPersons: LawPersonSearch[] = [];
  override petentData: PetentData;
  initialPayerCreated = false;
  addingPersonsDirectlyToDb: boolean;
  shouldSaveFormStateOnDestroy = true;
  mapParcelsObjectTableStateIndex = 0;
  mapIssueRangesObjectTableStateIndex = 1;
  designerSettings: DesignerSettings;
  allPossiblePersonTypes: PersonType[];
  allPossiblePersonTypesToChangeOnList: PersonType[];
  duplicatedSinglePersonTypes: string[];
  maxLengthDesignerRequest = MaxLengthDesignerRequest;
  legalPersonControlName = LegalPersonControlName;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private connectionAndNetworkService: ConnectionAndNetworkService,
    private newDesignerRequestService: NewDesignerRequestService,
    private formsStateService: FormsStateService,
    private designerIncomingDocumentsService: DesignerIncomingDocumentsService,
    private closedAreasService: ClosedAreasService,
    private ngbModal: NgbModal,
    private lawPersonService: LawPersonService,
    private personTypeService: PersonTypeService,
    private domSanitizer: DomSanitizer,
    @Inject('window') private window: Window,
    private settingsService: SettingsService,
    private designerSettingsService: DesignerSettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    protected override pzService: PzService,
    protected override newRequestHelperService: NewRequestHelperService,
    protected override docSignService: DocSignService,
    protected override router: Router,
    protected override translateService: TranslateService,
    protected override mapSettingsService: MapSettingsService,
    protected override mapStateService: MapStateService,
    protected override fileSaverService: FileSaverService,
    public docTypeService: DocTypeService,
    private sessionService: SessionService,
  ) {
    super(
      pzService,
      newRequestHelperService,
      docSignService,
      router,
      translateService,
      mapSettingsService,
      mapStateService,
    );
  }

  override ngOnInit(): void {
    this.subscribeToMapSettings();
    this.fetchWrongFileText();
    this.createForm();
    this.subscribeToConnectionsAndNetwork();
    this.subscribeToConnectionsAndNetworksTranslations();
    this.subscribeToClosedAreasResponse();
    this.checkAddingPersonsDirectlyToDb();
    this.addConnectionAndNetworkToTableWhenValueChanges();
    this.subscribeToSelectedPersonTypeValueChange();
    this.fetchAllPossiblePersonTypes();
  }

  checkAddingPersonsDirectlyToDb(): void {
    this.settingsService
      .getHubSettings()
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (settings) =>
          (this.addingPersonsDirectlyToDb = settings.addingPersonsDirectlyToDb),
      );
  }

  override subscribeToPetentData(shouldInitializeLawPersonList = true): void {
    this.pzService
      .getPetentData(true)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(async (petentData) => {
        if (_.isEmpty(petentData)) {
          return;
        }

        this.petentData = petentData;
        await this.addOrUpdateMainPerson();
        if (shouldInitializeLawPersonList) {
          this.handlePetentDataChange();
        }
      });
  }

  fetchAllPossiblePersonTypes(): void {
    this.personTypeService
      .getAllTypesWithProperRequiredAttributesForSelectedMainPersonOfDesginerPortal(
        this.chosenLawPersons,
      )
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((allPossiblePersonTypes) => {
        this.allPossiblePersonTypes = allPossiblePersonTypes;
        this.allPossiblePersonTypesToChangeOnList =
          allPossiblePersonTypes.filter(
            (personType) =>
              !this.personTypeService.isDesignerPersonType(personType),
          );
      });
  }

  handlePetentDataChange(): void {
    this.maybeInitChosenLawPersons();
    this.setFirstPossiblePersonType();
  }

  maybeInitChosenLawPersons(): void {
    if (this.getPersonsWithoutMainPerson().length || this.initialPayerCreated) {
      return;
    }

    this.personTypeService.personTypes
      .pipe(
        takeWhile(() => this.isAlive && !this.initialPayerCreated),
        filter((personTypes) => !_.isEmpty(personTypes)),
        switchMap(() => this.lawPersonService.getPayerFromLastRequest()),
      )
      .subscribe((payer) => {
        const payerPersonType = this.personTypeService.getPayerPersonType();
        this.chosenLawPersons = [
          ...this.chosenLawPersons,
          payer
            ? {
                ...payer,
                personType: payerPersonType,
              }
            : this.lawPersonService.getLawPersonSearchFromPetentData(
                this.petentData,
                payerPersonType,
              ),
        ];

        this.initialPayerCreated = true;
      });
  }

  getPersonsWithoutMainPerson(): LawPersonSearch[] {
    return this.chosenLawPersons.filter(
      (lawPerson) => !lawPerson.mainPersonOfDesginerPortal,
    );
  }

  handleChosenLawPersonsChange(): void {
    this.setFirstPossiblePersonType();
    this.changeDutifulnessOfPowerOfAttorney();
    this.validateDuplicatedNotMultiTypes();
  }

  changeDutifulnessOfPowerOfAttorney(): void {
    if (
      this.chosenLawPersons.find(
        (lp) =>
          lp.personType &&
          this.personTypeService.isPlenipotentiaryPersonType(lp.personType),
      )
    ) {
      this.docTypeService.changeDutifulnessOfPowerOfAttorney(true);
    } else {
      this.docTypeService.changeDutifulnessOfPowerOfAttorney(false);
    }
  }

  isPayerAdded(): boolean {
    return this.personTypeService.isPayerChosen(this.chosenLawPersons);
  }

  isRequiredPersonType(personTypeId: number): boolean {
    return requiredPersonTypes.includes(personTypeId);
  }

  setFirstPossiblePersonType(): void {
    this.getCurrentChosenPersonTypeFormControl().patchValue(
      _.first(this.allPossiblePersonTypes),
    );
    this.subscribeToDocSignTranslations();
  }

  subscribeToConnectionsAndNetworksTranslations(): void {
    this.translateService
      .get('CONNECTIONS_AND_NETWORKS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(
        (translations) =>
          (this.connectionsAndNetworksTranslations = translations),
      );
  }

  subscribeToMapSettings(): void {
    forkJoin([
      this.mapSettingsService.getMapSettings(MapPortalName.Designer),
      this.designerSettingsService.getDesignerSettings(),
    ])
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(([designerMapSettings, designerSettings]) => {
        this.designerSettings = designerSettings;
        this.setIssueRangesMapObjectTableState();
        this.setMapState(designerMapSettings);
      });
  }

  override setMapState(mapSettings: MapSettings): void {
    this.mapState = new MapState(
      MapId.NewDesignerRequestParcelsAndIssueRange,
      this.mapStateService.getViewState(
        MapId.NewDesignerRequestParcelsAndIssueRange,
        mapSettings,
      ),
      this.mapStateService.getToolbarState(
        [ToolType.LandParcel, ToolType.AnyPolygon],
        mapSettings.papers,
        undefined,
        undefined,
        true,
      ),
      this.initialToolsState,
      this.mapStateService.getLayersState(
        MapId.NewDesignerRequestParcelsAndIssueRange,
        mapSettings,
        MapPortalName.Designer,
      ),
      this.getMapObjectTablesState(),
    );
  }

  getMapObjectTablesState(): MapObjectTableState[] {
    const mapObjectTablesState = [];
    mapObjectTablesState[this.mapParcelsObjectTableStateIndex] =
      this.parcelsMapObjectTableState;
    mapObjectTablesState[this.mapIssueRangesObjectTableStateIndex] =
      this.issueRangesMapObjectTableState;

    return mapObjectTablesState;
  }

  getCurrentChosenPersonTypeFormControl(): AbstractControl {
    return this.formGroup.get('currentChosenPersonType');
  }

  createForm(): void {
    this.docFiles = this.formsStateService.newDesignerRequestDocFiles;

    if (
      this.formsStateService.chosenLawPersons &&
      this.formsStateService.chosenLawPersons.length
    ) {
      this.chosenLawPersons = this.formsStateService.chosenLawPersons;
    } else {
      this.subscribeToPetentData();
    }

    if (!_.isEmpty(this.formsStateService.newDesignerRequestForm)) {
      this.formGroup = this.formsStateService.newDesignerRequestForm;
      return;
    }

    this.formGroup = this.formBuilder.group({
      currentChosenPersonType: this.formBuilder.control(null),
      typedAddress: '',
      [this.controlName.ConnectionAndNetworkGroup]:
        this.createConnectionAndNetworkFormGroup(),
      [this.controlName.CouncilSubject]: this.formBuilder.control(
        '',
        Validators.maxLength(MaxLengthDesignerRequest.CouncilSubject),
      ),
      [NewDesignerRequestControlName.ConnectionAndNetworkGroupArray]:
        this.formBuilder.array([]),
      [NewDesignerRequestControlName.EmailingConsent]:
        this.formBuilder.control(true),
    });
  }

  subscribeToConnectionsAndNetwork(): void {
    this.connectionAndNetworkService.connectionsAndNetworks
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.connectionsAndNetworksOptions = data));
  }

  addOrUpdateMainPerson(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.personTypeService
        .getAvailablePersonTypesForPersonRelatedToLoggedUser()
        .pipe(
          takeWhile(() => this.isAlive),
          combineLatestWith(
            this.sessionService.getSystemLoggedUserAuthorizedPersonData(),
          ),
        )
        .subscribe({
          next: ([availablePersonTypes, authorizedPerson]) => {
            const mainPerson = this.getMainPersonOfDesignerPortal();
            if (mainPerson) {
              this.updateMainPerson(authorizedPerson);
              if (this.petentData.authorizedPersonId) {
                this.lawPersonService.selectedPersonValueChange.next(true);
              }
              resolve();
              return;
            }

            this.chosenLawPersons = [
              Applicant.createFromObjectParams({
                name: this.petentData.name,
                address: this.petentData.address,
                availablePersonTypes,
                canBeDeleted: false,
                pesel: this.petentData.pesel,
                mainPersonOfDesginerPortal: true,
                id: `${this.petentData.id}`,
                personType: this.petentData.authorizedPersonId
                  ? this.personTypeService.getDesignerPersonType()
                  : undefined,
                authorizedPerson: authorizedPerson.id
                  ? authorizedPerson
                  : undefined,
              }),
            ];
            if (this.petentData.authorizedPersonId) {
              this.lawPersonService.selectedPersonValueChange.next(true);
            }
            resolve();
          },
          error: (err) => reject(err),
        });
    });
  }

  updateMainPerson(authorizedPerson: NaturalPersonSearch): void {
    this.chosenLawPersons = this.chosenLawPersons.map((lawPerson) =>
      lawPerson.mainPersonOfDesginerPortal
        ? {
            ...lawPerson,
            personType: this.petentData.authorizedPersonId
              ? this.personTypeService.getDesignerPersonType()
              : undefined,
            authorizedPerson: authorizedPerson.id
              ? authorizedPerson
              : undefined,
          }
        : lawPerson,
    );
  }

  getMainPersonTypeOfDesignerPortal(): PersonType {
    const mainPersonOfDesignerPortal = this.getMainPersonOfDesignerPortal();

    return mainPersonOfDesignerPortal && mainPersonOfDesignerPortal.personType;
  }

  getMainPersonOfDesignerPortal(): LawPersonSearch {
    return this.chosenLawPersons.find(
      (lawPerson) => lawPerson.mainPersonOfDesginerPortal,
    );
  }

  setIssueRangesMapObjectTableState(): void {
    this.issueRangesMapObjectTableState = new MapObjectTableState(
      [new ColumnHeader('area', 'GK.MAP.AREA_IN_HA')],
      undefined,
      this.defaultMapGeometryStyles,
      true,
      true,
      true,
      '25',
      this.formsStateService.newDesignerRequestIssueRanges,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [MapObjectApiType.ExtentOrPolygon],
      this.designerSettings && this.designerSettings.rangeRequired,
    );
  }

  addConnectionAndNetworkToTableWhenValueChanges(): void {
    const connectionAndNetworkGroup = this.getConnectionAndNetworkGroup();
    connectionAndNetworkGroup.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((value) => {
        if (!value) {
          return;
        }
        this.addConnectionAndNetworkFormGroupToChosen(value);
        connectionAndNetworkGroup.setValue(
          {
            quantity: 1,
            connectionAndNetwork: null,
          },
          { emitEvent: false },
        );
      });
  }

  subscribeToSelectedPersonTypeValueChange(): void {
    this.lawPersonService.selectedPersonValueChange
      .pipe(
        takeWhile(() => this.isAlive),
        filter(Boolean),
      )
      .subscribe(() => {
        this.fetchAllPossiblePersonTypes();
        this.changeDutifulnessOfPowerOfAttorney();
        this.validateDuplicatedNotMultiTypes();
        this.changeDetectorRef.detectChanges();
      });
  }

  validateDuplicatedNotMultiTypes(): void {
    const duplicatedSingleTypes =
      this.personTypeService.getDuplicatedNotMultiTypes(this.chosenLawPersons);
    this.duplicatedSinglePersonTypes = duplicatedSingleTypes;
  }

  updatePersonTypeIsRequiredAttributeIfNeccessary(
    lawPerson: LawPersonSearch,
    availablePersonTypes: PersonType[],
  ): LawPersonSearch {
    const personTypeEquivalentFromDictionary = availablePersonTypes.find(
      (personType) => personType.id === lawPerson.personType.id,
    );
    return !lawPerson.mainPersonOfDesginerPortal &&
      lawPerson.personType.isRequired !==
        personTypeEquivalentFromDictionary.isRequired
      ? {
          ...lawPerson,
          personType: personTypeEquivalentFromDictionary,
        }
      : lawPerson;
  }

  createConnectionAndNetworkFormGroup(
    initialValue?: ConnectionAndNetworkFormGroupContent,
  ): AbstractControl {
    return this.formBuilder.group({
      quantity: initialValue ? initialValue.quantity : 1,
      connectionAndNetwork: initialValue
        ? initialValue.connectionAndNetwork
        : {},
    });
  }

  getConnectionAndNetworkGroupValue(): ConnectionAndNetworkFormGroupContent {
    return this.getConnectionAndNetworkGroup().value;
  }

  getConnectionAndNetworkGroup(): UntypedFormControl {
    return this.formGroup.get(
      this.controlName.ConnectionAndNetworkGroup,
    ) as UntypedFormControl;
  }

  getConnectionAndNetworkGroupArray(): UntypedFormArray {
    return this.formGroup.get(
      NewDesignerRequestControlName.ConnectionAndNetworkGroupArray,
    ) as UntypedFormArray;
  }

  isConnectionAndNetworkTypeValid(
    connectionAndNetwork?: ConnectionAndNetwork,
  ): boolean {
    return !!(connectionAndNetwork && connectionAndNetwork.id);
  }

  getNotChosenConnectionsAndNetworks(): ConnectionAndNetwork[] {
    return _.differenceWith(
      this.connectionsAndNetworksOptions as ConnectionAndNetwork[],
      this.getConnectionAndNetworkGroupArray().controls.map(
        (connectionAndNetworkGroup) =>
          connectionAndNetworkGroup.value.connectionAndNetwork,
      ),
      (x, y) => x.id === y.id,
    );
  }

  isNewConnectionAndNetworkValid(): boolean {
    this.connectionsAndNetworkError = '';
    const currentConnectionAndNetworkGroup =
      this.getConnectionAndNetworkGroupValue();
    const quantity = currentConnectionAndNetworkGroup.quantity;
    if (!this.isConnectionAndNetworkQuantityValid(quantity)) {
      this.connectionsAndNetworkError =
        this.connectionsAndNetworksTranslations['BAD_QUANTITY'];
      return false;
    }

    return true;
  }

  shouldShowCouncilSubjectFormControlLengthError(): boolean {
    return this.shouldShowErrorAlert(
      this.getCouncilSubjectFormControl(),
      'maxlength',
    );
  }

  getCouncilSubjectFormControl(): AbstractControl {
    return this.formGroup.get(this.controlName.CouncilSubject);
  }

  shouldShowErrorAlert(
    abstractControl: AbstractControl,
    errorName: string,
  ): boolean {
    return (
      this.isFieldReadyToShowAlert(abstractControl) &&
      this.hasError(abstractControl, errorName)
    );
  }

  isFieldReadyToShowAlert(abstractControl: AbstractControl): boolean {
    return !!(
      abstractControl &&
      (abstractControl.dirty || abstractControl.touched || this.submitted)
    );
  }

  hasError(abstractControl: AbstractControl, errorName: string): boolean {
    return !!(
      abstractControl &&
      abstractControl.errors &&
      abstractControl.errors[errorName]
    );
  }

  addConnectionAndNetworkFormGroupToChosen(
    value: ConnectionAndNetworkFormGroupContent,
  ): void {
    this.triedToAddConnectionAndNetwork = true;
    if (!this.isNewConnectionAndNetworkValid()) {
      return;
    }
    const connectionAndNetworkGroupArray =
      this.getConnectionAndNetworkGroupArray();
    connectionAndNetworkGroupArray.controls = _.uniqWith(
      [
        ...connectionAndNetworkGroupArray.controls,
        this.createConnectionAndNetworkFormGroup(value),
      ] as UntypedFormGroup[],
      (x, y) =>
        x.controls['connectionAndNetwork'].value &&
        y.controls['connectionAndNetwork'].value &&
        x.controls['connectionAndNetwork'].value.id ===
          y.controls['connectionAndNetwork'].value.id,
    );
  }

  areParcelsChosen(): boolean {
    const mapObjects =
      this.mapState.mapObjectTablesState[this.mapParcelsObjectTableStateIndex]
        .mapObjects;

    return !!(mapObjects && mapObjects.length);
  }

  isConnectionAndNetworkQuantityValid(quantity: number): boolean {
    const minQuantity = 1;
    const maxQuantity = 999999999;

    return (
      !(quantity % 1) && quantity >= minQuantity && quantity <= maxQuantity
    );
  }

  areConnectionsAndNetworksValid(): boolean {
    const chosenConnectionsAndNetworksGroups =
      this.getChosenConnectionsAndNetworksFormArrayValue();
    return (
      !!chosenConnectionsAndNetworksGroups.length &&
      chosenConnectionsAndNetworksGroups.every((connectionAndNetworkGroup) => {
        return (
          this.isConnectionAndNetworkTypeValid(
            connectionAndNetworkGroup.connectionAndNetwork,
          ) &&
          this.isConnectionAndNetworkQuantityValid(
            connectionAndNetworkGroup.quantity,
          )
        );
      })
    );
  }

  areConnectionsAndNetworksEmpty(): boolean {
    return _.isEqual(this.getConnectionAndNetworkGroupValue(), [
      ConnectionAndNetwork.getEmptyFormGroupContent(),
    ]);
  }

  areIssueRangesValid(): boolean {
    const mapObjects =
      this.mapState.mapObjectTablesState[
        this.mapIssueRangesObjectTableStateIndex
      ].mapObjects;

    return (
      !!(mapObjects && mapObjects.length) ||
      !this.designerSettings ||
      !this.designerSettings.rangeRequired
    );
  }

  isFormValid(): Observable<boolean> {
    this.validated = true;

    return this.areDocFilesAttachedAndValid().pipe(
      map(
        (areDocFilesAttachedAndValid) =>
          this.formGroup.valid &&
          this.areConnectionsAndNetworksValid() &&
          this.areParcelsChosen() &&
          areDocFilesAttachedAndValid &&
          this.areIssueRangesValid() &&
          this.isPayerAdded() &&
          this.isInvestorNotRequiredOrAdded() &&
          !!this.getMainPersonTypeOfDesignerPortal() &&
          !this.areDuplicatedNotMultiPersonTypes(),
      ),
    );
  }

  isInvestorNotRequiredOrAdded(): boolean {
    const investor = this.allPossiblePersonTypes.find((personType) =>
      this.personTypeService.isInvestorPersonType(personType),
    );
    const isInvestorRequired = investor && investor.isRequired;

    return (
      !isInvestorRequired ||
      this.personTypeService.isInvestorChosen(this.chosenLawPersons)
    );
  }

  areDuplicatedNotMultiPersonTypes(): boolean {
    return (
      this.duplicatedSinglePersonTypes &&
      !!this.duplicatedSinglePersonTypes.length
    );
  }

  areDocFilesAttachedAndValid(): Observable<boolean> {
    return this.docsTableEditableComponent
      .areFilesValid()
      .pipe(
        map(
          (areFilesValid) =>
            areFilesValid && this.areObligatoryDocFilesAttached(),
        ),
      );
  }

  areObligatoryDocFilesAttached(): boolean {
    return this.docTypeService.areObligatoryDocFilesAttached(
      this.docFiles,
      PortalType.Designer,
    );
  }

  resetData(): void {
    this.formGroup.reset();
    this.handleMapAction(new MapAction(MapObjectTableActionType.RemoveAll));
    this.docFiles = [];
    this.resetConnectionAndNetworkGroupArray();
    this.subscribeToPetentData();
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return this.docFiles.map((docFile) =>
      this.designerIncomingDocumentsService.docFileToDtoDocument(docFile),
    );
  }

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    this.isFormValid()
      .pipe(filter(Boolean))
      .subscribe(async () => {
        if (
          this.closedAreas.length &&
          !(await this.openCrossingClosedAreasModal())
        ) {
          return;
        }

        this.disabledSubmitButton = true;
        const apiFiles = await Promise.all(this.getConvertedFiles()).catch(
          () => new Error(),
        );

        if (apiFiles instanceof Error) {
          this.errorSubmitMessage = this.wrongFilesErrorText;
          this.disabledSubmitButton = false;
          return;
        }

        this.sendRequest(apiFiles as any as ApiNewDokumentPowiazanyDalDto[]);
      });
  }

  getFormValue(): NewDesignerRequestFormModel {
    return {
      ...this.formGroup.value,
      chosenParcels: this.getParcelsMapObjects(),
      issueRanges: this.getIssueRangesMapObjects(),
      connectionsAndNetworks:
        this.getChosenConnectionsAndNetworksFormArrayValue(),
      lawPersons: this.chosenLawPersons,
    };
  }

  private sendRequest(apiDocuments: ApiNewDokumentPowiazanyDalDto[]): void {
    this.docSignPending = true;
    this.setDocSignMsg(BsMessageType.Info, 'SENDING_REQUEST');
    this.getFormValueWithPersonPermissionNumber()
      .pipe(
        switchMap((formValue) =>
          this.newDesignerRequestService.validateDtoAndAddToSignOrDirectly(
            formValue,
            apiDocuments,
          ),
        ),
        takeWhile(() => this.isAlive),
      )
      .subscribe({
        next: (addedDocToSignResponse) => {
          this.handleNewDesignerRequestRequestSuccess(addedDocToSignResponse);
          this.disabledSubmitButton = false;
        },
        error: (err) => {
          console.error(err);
          this.handleSendAndValidateFailure();
          this.errorSubmitMessage =
            this.newDesignerRequestService.getResponseStatusMessage(err);
          this.disabledSubmitButton = false;
        },
        complete: () => {
          this.docSignPending = false;
          this.disabledSubmitButton = false;
          this.docSignMsgTxt = '';
        },
      });
  }

  isMainPersonIsDesigner(): boolean {
    return this.personTypeService.isDesignerPersonType(
      this.getMainPersonTypeOfDesignerPortal(),
    );
  }

  override handleDocSignSuccess(): void {
    this.subscribeToPetentData(false);
    this.docSignUrl = '';
    this.resetFormStateOnDestroy();
    this.router.navigateByUrl('/designer-portal/requests-list');
  }

  resetFormStateOnDestroy(): void {
    this.shouldSaveFormStateOnDestroy = false;
  }

  override openSigningWindow(windowUrl: string): void {
    this.window.open(windowUrl);
  }

  handleNewDesignerRequestRequestSuccess(
    addedDocToSignResponse: AddedDocToSignResponse,
  ): void {
    if (addedDocToSignResponse.directlyAddedRequest) {
      this.handleDocSignSuccess();

      return;
    }
    this.docSignUrl = this.domSanitizer.bypassSecurityTrustUrl(
      addedDocToSignResponse.docSigningUrl,
    );
    this.openSigningWindow(addedDocToSignResponse.docSigningUrl);
    this.waitForDocSign(addedDocToSignResponse.docKeyId);
  }

  handleChangeEmailingConsent(
    evt: Event,
    modalTemplate: TemplateRef<NgbModalRef>,
  ): void {
    const target = evt.target as HTMLInputElement;
    if (!target.checked) {
      this.openAndReturnModalRef(modalTemplate).then(undefined, () => {
        this.formGroup.patchValue({
          [this.controlName.EmailingConsent]: true,
        });
      });
    }
  }

  openAndReturnModalRef(
    modalTemplate: TemplateRef<NgbModalRef>,
  ): Promise<NgbModalRef> {
    return this.ngbModal.open(modalTemplate).result;
  }

  getPreviewRequestBody = (): Observable<ApiNewZudSprawaWniosekDalDto> => {
    return from(Promise.all(this.getConvertedFiles())).pipe(
      tap((apiFiles) => {
        if (_.some(apiFiles, (apiFile) => apiFile instanceof Error)) {
          throw new Error('Some file is wrong!');
        }
      }),
      combineLatestWith(this.getFormValueWithPersonPermissionNumber()),
      map(([apiFiles, formValue]) =>
        this.newDesignerRequestService.createNewDesignerRequestApiBody(
          formValue,
          apiFiles as any as ApiNewDokumentPowiazanyDalDto[],
        ),
      ),
      catchError((err) => {
        console.error(err);

        return throwError(() => err);
      }),
    );
  };

  getFormValueWithPersonPermissionNumber(): Observable<NewDesignerRequestFormModel> {
    const formValue = this.getFormValue();
    const isMainPersonIsDesigner = this.isMainPersonIsDesigner();
    return this.permissionNumberModalComponent
      .askForPermissionNumberWhenPetentDataHasNotIt(!isMainPersonIsDesigner)
      .pipe(
        map((permissionNumber) => ({
          ...formValue,
          lawPersons: formValue.lawPersons
            ? formValue.lawPersons.map((lawPerson) =>
                lawPerson.mainPersonOfDesginerPortal && !!permissionNumber
                  ? {
                      ...lawPerson,
                      dataToSend: {
                        ...(lawPerson.dataToSend || {}),
                        ...new NewDesignerRequestDto(
                          undefined,
                          undefined,
                          undefined,
                          1,
                          permissionNumber,
                        ),
                      } as NewNaturalPersonOrDesignerRequestDto,
                    }
                  : lawPerson.mainPersonOfDesginerPortal &&
                      isMainPersonIsDesigner &&
                      this.petentData.authorizedPersonId
                    ? {
                        ...lawPerson,
                        id: this.petentData.authorizedPersonId,
                      }
                    : lawPerson,
              )
            : formValue.lawPersons,
        })),
      );
  }

  getWorkspaceStateToSave = (): DesignerNewRequestFormWorkspaceState => {
    return {
      formValue: this.formGroup.value,
      chosenParcels: this.getParcelsMapObjects(),
      issueRanges: this.getIssueRangesMapObjects(),
      chosenConnectionsAndNetworksGroups:
        this.getChosenConnectionsAndNetworksFormArrayValue(),
      lawPersons: this.chosenLawPersons,
    };
  };

  addFiles(docFiles: DocFile[]): void {
    this.docFiles = [...this.docFiles, ...docFiles];
  }

  handleLoadWorkspaceResponse(
    state: DesignerNewRequestFormWorkspaceState,
  ): void {
    this.handleMapAction(
      new MapAction(
        MapObjectTableActionType.AddNew,
        [...state.chosenParcels],
        this.mapParcelsObjectTableStateIndex,
      ),
    );
    this.handleMapAction(
      new MapAction(
        MapObjectTableActionType.AddNew,
        [...state.issueRanges],
        this.mapIssueRangesObjectTableStateIndex,
      ),
    );
    this.formGroup.patchValue(state.formValue);
    this.setValueOfChosenConnectionsAndNetworksFormArray(
      state.chosenConnectionsAndNetworksGroups,
    );
    if (this.getIssueRangesMapObjects().length) {
      this.updateClosedAreas();
    }
    this.chosenLawPersons = state.lawPersons || [];
    this.handleChosenLawPersonsChange();
  }

  setValueOfChosenConnectionsAndNetworksFormArray(
    connectionAndNetworkFormGroupsContent: ConnectionAndNetworkFormGroupContent[],
  ): void {
    this.resetConnectionAndNetworkGroupArray();
    connectionAndNetworkFormGroupsContent.forEach(
      (connectionAndNetworkFormGroupContent) => {
        this.addConnectionAndNetworkFormGroupToChosen(
          connectionAndNetworkFormGroupContent,
        );
      },
    );
  }

  resetConnectionAndNetworkGroupArray(): void {
    this.formGroup.controls[
      NewDesignerRequestControlName.ConnectionAndNetworkGroupArray
    ] = this.formBuilder.array([]);
  }

  getChosenConnectionsAndNetworksFormArrayValue(): ConnectionAndNetworkFormGroupContent[] {
    return this.getConnectionAndNetworkGroupArray()
      .getRawValue()
      .filter((element) => element.quantity > 0);
  }

  openCrossingClosedAreasModal(): Promise<boolean> {
    return this.ngbModal
      .open(InfoCrossingClosedAreasModalComponent, { size: 'lg' })
      .result.then(
        () => true,
        () => false,
      );
  }

  subscribeToClosedAreasResponse(): void {
    this.closedAreasService.closedAreasResponse
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (closedAreas) => {
          this.closedAreasInvalid = false;
          this.closedAreas = closedAreas;
        },
        error: () => (this.closedAreasInvalid = true),
      });
  }

  updateClosedAreas(): void {
    this.closedAreasService.closedAreasRequest.next(
      this.getIssueRangesMapObjects(),
    );
  }

  ngOnDestroy(): void {
    if (this.shouldSaveFormStateOnDestroy) {
      this.formsStateService.newDesignerRequestForm = this.formGroup;
      this.formsStateService.newDesignerRequestChosenParcels =
        this.getParcelsMapObjects();
      this.formsStateService.newDesignerRequestDocFiles = this.docFiles;
      this.formsStateService.newDesignerRequestIssueRanges =
        this.getIssueRangesMapObjects();
      this.formsStateService.chosenLawPersons = this.chosenLawPersons;
    } else {
      this.formsStateService.reset();
    }

    this.isAlive = false;
  }

  getIssueRangesMapObjects(): MapObject[] {
    return _.get(
      this.mapState,
      `mapObjectTablesState[${this.mapIssueRangesObjectTableStateIndex}].mapObjects`,
      [],
    );
  }

  getParcelsMapObjects(): EgibObject[] {
    return _.get<MapState, any>(
      this.mapState,
      `mapObjectTablesState[${this.mapParcelsObjectTableStateIndex}].mapObjects`,
      [],
    ) as EgibObject[];
  }
}
