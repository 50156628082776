import { TemplateRef } from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog/window/models/window-ref';
import { WindowState } from '@progress/kendo-angular-dialog/window/models/window-types';
import { ExtendedGkKendoGridComponent } from '../../gk-kendo-grid/gk-kendo-grid.model';

export interface GkKendoWindowSettings {
  // cssClass: any;
  // state: WindowState;
  parent: ExtendedGkKendoGridComponent<any>;
  content: string | TemplateRef<any> | Function;
  windowInstanceName: KendoWindowInstanceNames;
  title?: string;
  width?: number;
  height?: number;
  left?: number;
  cssClass?: string;
  hiddenActionButtons?: GkKendoWindowActionButtons[];
  state?: WindowState;
  shouldShowWindow?: () => boolean;
  modal?: boolean;
  resizable?: boolean;
}

export interface GkWindowRef extends WindowRef {
  isWindowVisible: boolean;
}

export enum GkKendoWindowActionButtons {
  maximize = 'kendoWindowMaximizeAction',
  minimize = 'kendoWindowMinimizeAction',
  restore = 'kendoWindowRestoreAction',
  close = 'kendoWindowCloseAction',
}

export enum KendoWindowInstanceNames {
  ControlNetworkSearchWindow = 'controlNetworkSearchWindow',
  ControlNetworkDetailsWindow = 'controlNetworkDetailsWindow',
  ControlNetworkFileWindow = 'controlNetworkFileWindow',
  ControlNetworkPointCoordinatesWindow = 'controlNetworkPointCoordinatesWindow',
  ControlNetworkPointHeightsWindow = 'controlNetworkPointHeightsWindow',
  ControlNetworkPointDirectionPointsWindow = 'controlNetworkPointDirectionPointsWindow',
  ControlNetworkPointLinesWindow = 'controlNetworkPointLinesWindow',
  ControlNetworkPointExcentresWindow = 'controlNetworkPointExcentresWindow',
  SurveyorPortalWindow = 'surveyorPortalWindow',
  SurveyorPortalFilesToDownload = 'surveyorPortalFilesToDownload',
  SurveyorPortalNewRequestWindow = 'surveyorPortalNewRequestWindow',
  SupplementaryRequestListWindow = 'supplementaryRequestListWindow',
  SupplementaryRequestDetailsWindow = 'supplementaryRequestDetailsWindow',
  WorkReportsWindow = 'workReportsWindow',
  WorkReportsDetails = 'workReportsDetails',
  WorkAttachmentsWindow = 'workAttachmentsWindow',
  RequestRemoteDBUpdateWindow = 'requestRemoteDBUpdateWindow',
  RemoteDBUpdateOutgoingDocumentsWindow = 'remoteDBUpdateOutgoingDocumentsWindow',
  PaymentsWindow = 'paymentsWindow',
  LandDetailsWindow = 'landDetailsWindow',
  BuildingDetailsWindow = 'buildingDetailsWindow',
  DocsFromSuveyorCenter = 'docsFromSurveyorCenter',
  InfoFromSuveyorCenter = 'infoFromSurveyorCenter',
  OtherWorkInProgress = 'otherWorkInProgress',
  OtherWorkMaterials = 'otherWorkMaterials',
  OtherWorkMaterialsDetails = 'otherWorkMaterialsDetails',
  CurrentDataSetOrderWindow = 'currentDataSetOrderWindow',
  GkKendoUpload = 'gkKendoUpload',
  GkKendoPreview = 'gkKendoPreview',
  ParcelsForWork = 'parcelsForWork',
  ParcelsForReport = 'parcelsForReport',
  WorkDetails = 'workDetails',
  PendingWorkDetails = 'pendingWorkDetails',
  NewRequestRemoteDataUpdate = 'newRequestRemoteDataUpdate',
  OtherWorkDetails = 'otherWorkDetails',
  MessagesList = 'messagesList',
  PendingMessagesList = 'pendingMessagesList',
  Communicator = 'communicator',
  PendingWorksCommunicator = 'pendingWorksCommunicator',
  WorkArrangements = 'workArrangements',
  WorkArrangementsDetails = 'workArrangementsDetails',
  ReportsInScopeOfWork = 'reportsInScopeOfWork',
  WorkDocumentations = 'workDocumentations',
  ReportsInScopeOfWorkDetails = 'reportsInScopeOfWorkDetails',
  NamePatternsFile = 'namePatternsFile',
  SizeReportLimit = 'sizeReportLimit',
  SearchByCriteria = 'searchByCriteria',
  AnalogMapsInScope = 'analogMapsInScope',
  AnalogMapsDetails = 'analogMapsDetails',
  VerificationProtocolsWindow = 'verificationProtocolsWindow',
  BorderPointsReservation = 'borderPointsReservation',
  NewBorderPointsReservation = 'newBorderPointsReservation',
  FeeAttachmentsWindow = 'feeAttachmentsWindow',
  ApFeeAttachmentsWindow = 'APfeeAttachmentsWindow',
  ReportsInScopeSearchWindow = 'reportsInScopeSearchWindow',
  GeomFileSelection = 'geomFileSelection',
  IdsFileSelection = 'idsFileSelection',
  CreateRangeBySheet = 'createRangeBySheet',
  ParcelPreviewChanges = 'parcelPreviewChanges',
  ModalParcelsWithOtherWorksInfo = 'modalParcelsWithOtherWorksInfo',
  SelectProviderPaymentWindow = 'selectProviderPaymentWindow',
  LandParcelSearch = 'landParcelSearch',
  EndOfWorkReport = 'endOfWorkReport',
  DocsComponentDetails = 'docsComponentDetails',
  DocsReportDetails = 'docsReportDetails',
  AnalogMapsDocsDetails = 'analogMapsDocsDetails',
  OtherMaterialsDocsDetails = 'otherMaterialsDocsDetails',
  DocumentPreview = 'documentPreview',
  ControlNetworkOptionsWindow = 'controlNetworkOptionsWindow',
  MapSheetForm = 'mapSheetForm',
  NewSupplementaryRequestWindow = 'newSupplementaryRequestWindow',
  ResourceMaterialListForWork = 'resourceMaterialListForWork',
  FeeSelection = 'feeSelection',
  ParcelInRange = 'parcelInRange',
  SearchWorksByCriteria = 'searchWorksByCriteria',
  SearchComponentDocsReport = 'searchComponentDocsReport',
  AppraiserTransactionWindow = 'appraiserTransactionWindow',
  AppraiserTypePatternsWindow = 'appraiserTypePatternsWindow',
  AppraiserHistoryInsightWindow = 'appraiserHistoryInsightWindow',
  AccessCollectionOfDocuments = 'accessCollectionOfDocuments',
  AppraiserRequestsListWindow = 'appraiserRequestsListWindow',
  AppraiserDocumentSetWindow = 'appraiserDocumentSetWindow',
  CoordinationMeetingRequestsList = 'coordinationMeetingRequestsList',
  CmpRequestsSearchWindow = 'cmpRequestsSearchWindow',
  CmpRequestIncomingDocumentsWindow = 'cmpRequestIncomingDocumentsWindow',
  CmpRequestOutgoingDocumentsWindow = 'cmpRequestOutgoingDocumentsWindow',
  GkSummaryApplication = 'gkSummaryApplication',
  ApOrderSummaryWindow = 'apOrderSummaryWindow',
  ApOrderSummaryWrapperComponent = 'apOrderSummaryWrapperComponent',
  ApRequestForTransactionWindow = 'apRequestForTransactionWindow',
  CmpRequestsDetailsWindow = 'cmpRequestsDetailsWindow',
  CmpCaseStanceAddWindow = 'cmpCaseStanceAddWindow',
  CmpCaseInsertDefinedText = 'cmpCaseInsertDefinedText',
  CmpCaseAddDefinedText = 'cmpCaseAddDefinedText',
  CmpCaseModifyDefinedText = 'cmpCaseModifyDefinedText',
}
