import {
  ApiNewDokumentPowiazanyDalDto,
  EgibObject,
  Place,
  Street,
} from '@gk/gk-modules';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentationFormType,
  ExtendedApplicantRequest,
  ExtendedApplicantRequestDto,
  ZamowienieDzialkaDto,
} from '../../../../../shared/base-new-request/base-new-request.model';
import { InvestorDetails } from '../../../../../shared/investor-details/investor-details.model';
import { Shipping } from '../../../../../shared/shipping-form/shipping-form.model';
import { DateHelperUtil } from '../../../../../utils/date-helper/date-helper.util';
import { FellingPurpose } from '../new-request-form/new-request-form.model';

export interface TASFNewRequestDto extends ExtendedApplicantRequestDto {
  MiejscId: number | string;
  Miejsc: string;
  UlicaId: number | string;
  Ulica: string;
  Numer: string;
  NrKw: string;
  PrzyczynaUsuniecia: string;
  MiejsceRosniecia: string;
  CelZwiazanyZDzialalnosciaGospodarcza: boolean;
  PlanowanyTermin: string;
  OpisInwestycji: string;
  Drzewa: TreeRequestDto[];
  Krzewy: ShrubRequestDto[];
}

export interface ObjectForFelling {
  inventoryNumber: string;
  speciesName: string;
}

export interface Tree extends ObjectForFelling {
  trunkCircumference: number;
}

export interface Shrub extends ObjectForFelling {
  projectionSurface: number;
}

export abstract class ObjectForFellingRequestDto {
  constructor(
    public NrInwentaryzacyjny: string,
    public NazwaGatunku: string,
  ) {}
}

export class TreeRequestDto extends ObjectForFellingRequestDto {
  constructor(
    public override NrInwentaryzacyjny: string,
    public override NazwaGatunku: string,
    public ObwodPnia: number,
  ) {
    super(NrInwentaryzacyjny, NazwaGatunku);
  }

  static fromApiToApp(data: Tree): TreeRequestDto {
    return new this(
      data.inventoryNumber,
      data.speciesName,
      data.trunkCircumference,
    );
  }
}

export class ShrubRequestDto extends ObjectForFellingRequestDto {
  constructor(
    public override NrInwentaryzacyjny: string,
    public override NazwaGatunku: string,
    public PowierzchniaRzutu: number,
  ) {
    super(NrInwentaryzacyjny, NazwaGatunku);
  }

  static fromApiToApp(data: Shrub): ShrubRequestDto {
    return new this(
      data.inventoryNumber,
      data.speciesName,
      data.projectionSurface,
    );
  }
}

export class TASFNewRequest {
  constructor(
    public investorDetails: InvestorDetails,
    public proxyDetails: InvestorDetails,
    public proxyDetailsCheckbox: boolean,
    public attachments: ApiNewDokumentPowiazanyDalDto,
    public documentationForm: DocumentationFormType,
    public shippingForm: Shipping,
    public treesList: Tree[],
    public shrubsList: Shrub[],
    public businessPurpose: FellingPurpose,
    public fellingCause: string,
    public fellingDate: NgbDate,
    public growthPlace: string,
    public investmentDescription: string,
    public landParcel: ZamowienieDzialkaDto,
    public fellingPlace: Place,
    public fellingStreet: Street,
    public landRegister: string,
    public fellingLandNumber: string,
  ) {}
  static fromAppToApi(
    data: TASFNewRequest,
    parcels: EgibObject[],
    issueRanges: string[],
    files: ApiNewDokumentPowiazanyDalDto[],
  ): TASFNewRequestDto {
    return {
      ...ExtendedApplicantRequest.mapCommonFields({
        data,
        parcels,
        issueRanges,
        files,
      }),
      MiejscId: data.fellingPlace.id,
      Miejsc: data.fellingPlace.name,
      UlicaId: data.fellingStreet && data.fellingStreet.id,
      Ulica: data.fellingStreet && data.fellingStreet.name,
      Numer: data.fellingLandNumber,
      NrKw: data.landRegister,
      PrzyczynaUsuniecia: data.fellingCause,
      MiejsceRosniecia: data.growthPlace,
      CelZwiazanyZDzialalnosciaGospodarcza:
        data.businessPurpose === FellingPurpose.Business,
      PlanowanyTermin: DateHelperUtil.ngbDateToApiDateTime(data.fellingDate),
      OpisInwestycji: data.investmentDescription,
      Drzewa: data.treesList.map((tree) => TreeRequestDto.fromApiToApp(tree)),
      Krzewy: data.shrubsList.map((shrub) =>
        ShrubRequestDto.fromApiToApp(shrub),
      ),
    };
  }
}
